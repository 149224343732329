import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import FAQs from "../components/FAQs/FAQs";
import { WebsiteContext } from "../context/websiteContext";
import { useContext } from "react";
import qs from "qs";
import { FETCH_GUIDE_DETAILS } from "../api/constants";
import axios from "axios";
import Markdown from "react-markdown";
import { Helmet } from "react-helmet";

export default function Guides() {
  const { setShowAreas } = useContext(WebsiteContext);
  const [guideDetails, setGuideDetails] = useState(null);
  const { slug } = useParams();
  const pathname = useLocation().pathname;

  useEffect(() => {
    setShowAreas(true);
  }, []);

  const fetchGuideDetails = async () => {
    const query = qs.stringify(
      {
        filters: {
          guide_slug: {
            $eq: slug,
          },
          show_guide: {
            $eq: true,
          },
        },
        populate: [
          "guide_featured_image",
          "section2",
          "section2.section2_image",
          "guide_faqs",
          "where_next",
          "where_next.icon",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const response = await axios.get(`${FETCH_GUIDE_DETAILS}?${query}`);
    setGuideDetails(response.data.data[0]);
  };

  useEffect(() => {
    fetchGuideDetails();
  }, [slug]);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div>
        {/* Hero Section */}
        <div
          className="relative flex flex-col justify-center items-center text-center h-[85vh] md:h-[65vh] py-32 md:py-52 lg:py-72 xl:py-0 xl:w-full xl:h-[100vh] 2xl:py-0  2xl:w-full 2xl:h-[70vh] bg-cover bg-center"
          style={{
            backgroundImage: `url(${
              guideDetails?.guide_featured_image?.formats?.large?.url
                ? guideDetails?.guide_featured_image?.formats?.large?.url
                : guideDetails?.guide_featured_image?.url
            })`,
          }}
        >
          {/* Dark overlay for better text readability */}
          <div className="absolute inset-0 bg-black/40"></div>

          {/* Content */}
          <div className="relative z-10 sm:px-6 xl:w-[1200px] mx-auto lg:px-8">
            <h1 className="text-2xl md:text-3xl  lg:text-4xl w-[80%] sm:w-[350px] md:w-[600px] lg:w-[900px] 2xl:w-[1200px] font-bold text-white mb-6 tracking-wider mx-auto">
              {guideDetails?.hero_heading}
            </h1>
            <div className="prose text-sm md:text-base md:w-[700px]  w-[90%] lg:w-[600px] lg:text-lg 2xl:w-[800px] text-white max-w-3xl mx-auto mb-8 tracking-wide">
              <Markdown>{guideDetails?.hero_subheading}</Markdown>
            </div>

            {/* CTA Buttons */}
            <div className="flex flex-col items-center sm:flex-row gap-4 justify-center w-fit  md:w-[500px] mx-auto lg:w-[900px]">
              <Link
                reloadDocument
                to={guideDetails?.hero_btn1_url}
                className="inline-flex justify-center w-[300px] sm:w-[300px] items-center gap-2 bg-white text-black px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
              >
                <span className="font-medium">
                  {guideDetails?.hero_btn1_text}
                </span>
                <svg
                  className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
              <Link
                reloadDocument
                to={guideDetails?.hero_btn2_url}
                className="inline-flex w-[300px] sm:w-[300px] justify-center items-center gap-2 bg-white text-black px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
              >
                <span className="font-medium">
                  {guideDetails?.hero_btn2_text}
                </span>
                <svg
                  className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        {/* Guide Sections */}
        <div className="px-4 sm:px-6 lg:px-8 pt-4 ">
          {/* Section 1 start*/}
          <section className="grid lg:grid-cols-1 gap-8 mt-8 md:mt-8 items-center">
            <div className=" w-[95%] md:w-[700px] lg:w-[900px] xl:w-[1200px] mx-auto">
              <h2 className="text-xl md:text-2xl text-center font-semibold mb-4 xl:w-[1200px]">
                {guideDetails?.section1_heading}
              </h2>
              <div className="prose text-gray-600 text-sm md:text-base md:w-[600px] md:mx-auto lg:w-[700px] xl:w-[900px] xl:mx-auto text-justify lg:text-center tracking-wide">
                <Markdown>{guideDetails?.section1_subtext_new}</Markdown>
              </div>
            </div>
          </section>
          {/* Section 1 end*/}

          {/* Section 2 - alternating sections start*/}
          {guideDetails?.section2.map((section, index) => (
            <section
              key={index}
              className="grid lg:grid-cols-2 gap-8 items-center mt-10 lg:mt-20 xl:items-center xl:w-[1200px] xl:mx-auto xl:mt-20 xl:gap-0 "
            >
              <img
                src={`${
                  section?.section2_image?.formats?.medium?.url
                    ? section?.section2_image?.formats?.medium?.url
                    : section?.section2_image?.url
                }`}
                alt="Property requirements"
                className={`rounded-lg shadow-lg w-full md:w-[700px] aspect-video object-cover md:mx-auto ${
                  index % 2 === 0 ? "lg:order-1" : "lg:order-2"
                }`}
              />
              <div
                className={`md:w-[700px] lg:w-auto xl:w-auto  ${
                  index % 2 === 0
                    ? "lg:order-2 mx-auto"
                    : "lg:order-1 md:mx-auto lg:mx-0"
                } `}
              >
                <h2 className="text-xl md:mt-4 md:text-2xl text-center md:text-left  font-semibold mb-4">
                  {section?.section2_heading}
                </h2>
                <div className="prose prose-ul:text-justify text-gray-600 text-sm md:text-base text-justify md:text-justify xl:w-[500px] max-w-none">
                  <Markdown>{section?.section2_subtext}</Markdown>
                </div>
              </div>
            </section>
          ))}
          {/* Section 2 - alternating sections end*/}
        </div>

        {/* FAQ Section */}
        <div className="bg-gray-50 pt-8 mt-8 xl:pt-16">
          <h2 className="text-xl font-semibold mb-8 text-center">
            Frequently Asked Questions
          </h2>
          <div className="w-[90%] md:w-[700px] lg:w-[900px] xl:w-[1200px] mx-auto">
            <FAQs faqs={guideDetails?.guide_faqs} />
          </div>
        </div>

        {/* Communities Section */}
        <div className="mt-8 w-[90%] md:w-[700px] lg:w-[900px] xl:w-[1200px] mx-auto">
          <h2 className="text-xl font-semibold mb-8">Where next?</h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">
            {guideDetails?.where_next?.map((item, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-start "
              >
                <div className="w-10 h-10 mb-6">
                  <img
                    src={`${item?.icon?.url}`}
                    alt={item?.icon?.alternativeText}
                    className="w-full h-full"
                  />
                </div>
                <h3 className="text-base font-semibold mb-6 text-left">
                  {item?.title}
                </h3>

                <span className="text-blue-600 font-medium flex items-center gap-2">
                  <a href={item?.link_url}>{item?.link_text}</a>

                  <svg
                    className="w-4 h-4 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
