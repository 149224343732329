import React, { useState } from "react";

import axios from "axios";
import { CONTACT_US, CONTACT_US_CAREER, RESUME_UPLOAD } from "../api/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const setItemWithExpiry = (key, value, expiryInMilliseconds) => {
  const now = new Date();

  // Create an item with the value and the expiry time
  const item = {
    value: value,
    expiry: now.getTime() + expiryInMilliseconds,
  };

  // Save the item to localStorage
  localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // If the key does not exist, return null
  if (!itemStr) {
    return { isValid: false, value: null };
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // If the expiry time is in the past, remove the item and return false
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return { isValid: false, value: null };
  }

  // If the item is not expired, return true and the value
  return { isValid: true, value: item.value };
};

function ContactUsCareersPage({ formFields, contactDetails }) {
  const { pathname } = useLocation();
  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
    pageUrl: "",
    ipAddress: "",
    formId: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResumeUplaoded, setIsResumeUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resumeSubmitted, setIsResumeSubmitted] = useState(
    getItemWithExpiry("resume_submitted").isValid &&
      getItemWithExpiry("resume_submitted").value === true
      ? true
      : false
  );

  const [formSubmitted, setIsFormSubmitted] = useState(
    getItemWithExpiry("form_submitted").isValid &&
      getItemWithExpiry("form_submitted").value === true
      ? true
      : false
  );

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!resumeSubmitted) {
      toast.info("Please upload your resume");
      return;
    }

    if (
      getItemWithExpiry("form_submitted").isValid &&
      getItemWithExpiry("form_submitted").value === true
    ) {
      toast.info("Form was already submitted. Try Again after 24 hours.");
      return;
    }

    setIsSubmitting(true);
    try {
      // formData.pageUrl = `https://www.xrealty.ae${pathname}`;
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      formData.formId = getItemWithExpiry("form_id").value
        ? getItemWithExpiry("form_id").value
        : "";

      if (formData.formId === "") {
        toast.error(
          "Something Went Wrong. Please, Refresh the page and try again."
        );
        return;
      }
      await axios.post(CONTACT_US_CAREER, formData);
      // Show success toast
      // toast.success("Form submitted successfully!");

      // Show confirmation popup
      setIsSubmitted(true);

      setIsFormSubmitted(true);

      setItemWithExpiry("form_submitted", true, 24 * 60 * 60 * 1000);

      // Hide the popup after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
      // Reset form data after successful submission
      setFormData(initialFormData);
      // Push custom event to dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Career Page Lead form submit",
      });

      ReactPixel.trackCustom("Career Page Lead form submit", {
        // Optional parameters
        page: pathname,
      });

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      toast.error(error.response?.data?.error + "");
      console.error(error.response?.data?.error);
    }
  };

  const handleResumeUpload = async (e) => {
    e.preventDefault();

    const file = document.getElementById("upload_file").files[0];

    if (!file) {
      toast.error("Please Select a file");
      return;
    }

    if (resumeSubmitted) {
      toast.error(
        "A resume file was already submitted. Try Again after 24 hours."
      );
      return;
    }

    if (file.size / (1024 * 1024) > 5) {
      toast.error("File Size Larger Than 5 MB");
      return;
    }
    const formData = new FormData();
    formData.append("resume", file);
    setIsUploading(true); // Start uploading indicator

    const res = await axios.post(RESUME_UPLOAD, formData);
    if (res.status === 201) {
      // console.log(res);
      setItemWithExpiry("resume_submitted", true, 24 * 60 * 60 * 1000); // 1 day
      setItemWithExpiry("form_id", res?.data?.id, 60 * 1000); // 1 minute
      setIsResumeUploaded(true);
      setIsResumeSubmitted(true);
      setIsUploading(false); // Set the uploaded state
      // CLose the modal
      setTimeout(() => {
        setIsResumeUploaded(false);
      }, 500);
      // setFormData(initialFormData);
      return;
    }

    setIsUploading(false);
    toast.error("Something Went Wrong. Please, Try Again After Sometime");
    return;
  };

  return (
    <div className="w-full mx-auto px-4 py-16 sm:px-6">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Form Section - Takes up 2 columns */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-2xl mb-12 lg:mb-0">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <input
                    className="w-full border border-gray-300 p-3 text-sm md:text-base"
                    placeholder="First Name"
                    type="text"
                    id="firstname"
                    required
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    className="w-full border border-gray-300 p-3 text-sm md:text-base"
                    placeholder="Last Name"
                    type="text"
                    id="lastname"
                    required
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <input
                  className="w-full border border-gray-300 p-3 text-sm md:text-base"
                  placeholder="Email"
                  type="email"
                  id="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  className="w-full border border-gray-300 p-3 text-sm md:text-base"
                  placeholder="Phone"
                  type="tel"
                  id="phone"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <textarea
                  className="w-full border border-gray-300 p-3 text-sm md:text-base"
                  placeholder="Message"
                  rows="4"
                  id="message"
                  value={formData.message}
                  onChange={(e) => {
                    if (e.target.value.length <= 500) {
                      handleChange(e);
                    }
                  }}
                  maxLength="500"
                ></textarea>
                <p className="text-gray-400 text-sm md:text-base">
                  {formData.message.length} of 500 max characters.
                </p>
              </div>
              <div>
                <label>
                  Only PDF File Allowed, File Size Should Be Less than 5MB
                </label>
                <div className="flex flex-row">
                  <input
                    className="w-full rounded-lg border border-gray-300 p-1 text-sm md:text-base"
                    placeholder="Upload File"
                    type="file"
                    id="upload_file"
                    name="upload_file"
                    accept="application/pdf"
                    multiple={false}
                    size={5000000}
                  />

                  <button
                    // disabled={resumeSubmitted}
                    type="submit"
                    className={`rounded-lg inline-block w-[250px] bg-black px-5 font-medium text-white hover:text-black hover:bg-white hover:border-black hover:border ${
                      isUploading ? "cursor-wait" : ""
                    }`}
                    onClick={handleResumeUpload}
                  >
                    {isUploading
                      ? "Uploading..."
                      : isResumeUplaoded
                      ? "Uploaded"
                      : "Upload Resume"}
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-block rounded-lg w-full bg-black px-5 py-3 font-medium text-white hover:text-black hover:bg-white hover:border-black hover:border disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Submitting...
                    </span>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Contact Details Section - Takes up 1 column */}
        <div className="bg-white rounded-2xl">
          <div className="grid grid-cols-1  gap-8">
            {contactDetails.map((detail) => (
              <div
                key={detail.label}
                className="flex items-start space-x-6 group hover:bg-gray-50 p-4 rounded-lg transition duration-200"
              >
                <div className="flex-shrink-0">
                  <a
                    href={detail.action}
                    className="block rounded-full bg-gray-100 p-3 group-hover:bg-white transition duration-200"
                  >
                    <img
                      className="w-6 h-6"
                      src={detail.icon}
                      alt={detail.label}
                    />
                  </a>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {detail.label}
                  </h3>
                  <p className="mt-2 text-gray-600">{detail.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {(isSubmitted || isResumeUplaoded) && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl w-96 text-center transform transition-all">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
              <svg
                className="h-6 w-6 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              {isSubmitted
                ? "Form Submitted Successfully!"
                : "Resume Uploaded Successfully!"}
            </h3>
            <p className="text-gray-600 mb-6">
              {isSubmitted
                ? "Thank you for your submission. We'll get back to you soon."
                : "Your resume has been uploaded. Please proceed with the form submission."}
            </p>
            <button
              onClick={() => {
                isSubmitted
                  ? setIsSubmitted(false)
                  : setIsResumeUploaded(false);
              }}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactUsCareersPage;
