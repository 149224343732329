import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

function formatPrice(price) {
  // Remove any non-numeric characters except decimal points
  const numericPrice = parseFloat(price.toString().replace(/[^0-9.]/g, ""));

  if (isNaN(numericPrice)) return price;

  // Convert to millions if the number is >= 1 million
  if (numericPrice >= 1000000) {
    const inMillions = numericPrice / 1000000;
    // Round to 1 decimal place
    const rounded = Math.round(inMillions * 10) / 10;
    return `AED ${rounded}M`;
  }

  // Convert to thousands if the number is >= 1 thousand
  if (numericPrice >= 1000) {
    const inThousands = numericPrice / 1000;
    const rounded = Math.round(inThousands * 10) / 10;
    return `AED ${rounded}K`;
  }

  // Return the original format if less than 1000
  return `AED ${numericPrice}`;
}

// Add this helper function before the FeaturedProperties component
function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export default function FeaturedProperties({ property }) {
  const [currentTypeIndex, setCurrentTypeIndex] = React.useState(0);
  const [isRotating, setIsRotating] = React.useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  // Function to handle type rotation
  const rotateType = React.useCallback(() => {
    // Only rotate if there are multiple types
    if ((property?.type?.length || 0) > 1) {
      setIsRotating(true);
      setTimeout(() => {
        setCurrentTypeIndex((prev) => (prev + 1) % property?.type?.length);
        setIsRotating(false);
      }, 750);
    }
  }, [property?.type?.length]);

  // Set up interval for rotation only if there are multiple types
  React.useEffect(() => {
    if ((property?.type?.length || 0) > 1) {
      const interval = setInterval(rotateType, 4000);
      return () => clearInterval(interval);
    }
  }, [property?.type?.length, rotateType]);

  return (
    <Link
      to={`/property/${property.property_name_slug}/`}
      reloadDocument
      className="relative w-full h-[300px] rounded-lg overflow-hidden"
    >
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${
            isMobile || isTablet
              ? property?.images[0]?.url.replace(
                  "https://res.cloudinary.com/dkhns25jh/image/upload/",
                  "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,q_auto,f_auto/"
                )
              : property?.images[0]?.url
          })`,
        }}
      >
        {/* Overlay for better text visibility */}
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
      </div>

      {/* Top Labels */}
      <div className="absolute top-4 right-4 flex gap-3">
        <span className="px-4 py-1 bg-black text-white rounded-full text-sm font-medium">
          {property?.status[0]?.toUpperCase()}
        </span>
        <span className="px-4 py-1 bg-white  bg-opacity-90 rounded-full text-sm font-medium">
          {property?.price}
        </span>
      </div>

      {/* Bottom Content */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent">
        <h3 className="text-white text-xl font-medium mb-2">
          {property?.property_name && toTitleCase(property.property_name)}
        </h3>
        <div className="flex flex-wrap gap-2">
          <span
            className={`px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm
              transition-all duration-1000 ease-in-out
              ${
                (property?.type?.length || 0) > 1 && isRotating
                  ? "animate-rotate-x"
                  : ""
              }`}
            style={{
              transformStyle: "preserve-3d",
              perspective: "1000px",
            }}
          >
            {property?.type[currentTypeIndex]?.bedrooms} BR{" "}
            {property?.type[currentTypeIndex]?.name.toUpperCase()}
          </span>
          <span className="px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm">
            {property?.developer}
          </span>
          <span className="px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm">
            {property?.community_name}
          </span>
        </div>
      </div>
    </Link>
  );
}
