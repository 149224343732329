import React from "react";

const FeaturedPropertiesShimmer = () => {
  return (
    <div className="relative w-full h-[300px] rounded-lg overflow-hidden bg-gray-200 animate-pulse">
      {/* Top Labels */}
      <div className="absolute top-4 right-4 flex gap-3">
        <div className="w-20 h-6 bg-gray-300 rounded-full"></div>
        <div className="w-24 h-6 bg-gray-300 rounded-full"></div>
      </div>

      {/* Bottom Content */}
      <div className="absolute bottom-0 left-0 right-0 p-4">
        {/* Title */}
        <div className="w-3/4 h-7 bg-gray-300 rounded mb-3"></div>

        {/* Tags */}
        <div className="flex flex-wrap gap-2">
          <div className="w-24 h-6 bg-gray-300 rounded-full"></div>
          <div className="w-32 h-6 bg-gray-300 rounded-full"></div>
          <div className="w-36 h-6 bg-gray-300 rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedPropertiesShimmer;
