import React, { useContext, useEffect, useState } from "react";
import "../components/pagination.css";
import {
  FETCH_ALL_AGENTS_PAGE_STRAPI,
  WEB_URL,
  XR_TEAM,
} from "../api/constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { WebsiteContext } from "../context/websiteContext";
import AgentCardV2 from "../components/AgentCard/AgentCardV2";
import Markdown from "react-markdown";

function XrTeam() {
  const [topSection, setTopSection] = useState();
  const [agents, setAgents] = useState([]);
  const [bottomSection, setBottomSection] = useState();
  const [agentsPageData, setAgentsPageData] = useState([]);
  const { pathname } = useLocation();

  const { setShowAreas } = useContext(WebsiteContext);

  const fetchAgents = async () => {
    try {
      const response = await axios.get(`${XR_TEAM}`);
      setTopSection(response?.data?.topSection);
      setAgents(response?.data?.agents);
      setBottomSection(response?.data?.bottomSection);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAgentsPageDataStrapi = async () => {
    try {
      const response = await axios.get(`${FETCH_ALL_AGENTS_PAGE_STRAPI}`);
      setAgentsPageData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAgents();
    fetchAgentsPageDataStrapi();
  }, []);

  useEffect(() => {
    setShowAreas(true);
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "Xperience Realty | Real Estate Dubai",
            description:
              "Meet our experienced qualified team of the best Dubai real estate agents who cater to all your real estate needs",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: `${WEB_URL}${pathname.slice(1)}`,
          })}
        </script>
      </Helmet>

      <div className="pt-8 pb-4 px-4 container mx-auto md:pt-16">
        {/* Top section heading and description */}
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="text-xl font-bold">
            {agentsPageData?.top_section_heading}
          </h1>
          <div className="text-gray-600 text-sm leading-relaxed text-justify prose max-w-none">
            <Markdown>{agentsPageData?.top_section_description}</Markdown>
          </div>
        </div>

        {/* Agents cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {agents.map((person, index) => (
            <div key={index}>
              <AgentCardV2 person={person} />
            </div>
          ))}
        </div>

        {/* Bottom section content */}
        <div className="pt-16">
          <div className="gap-4 flex flex-col text-sm leading-relaxed text-gray-600 text-justify prose max-w-none">
            <Markdown>{agentsPageData?.bottom_section_content}</Markdown>
          </div>
        </div>
      </div>
    </>
  );
}

export default XrTeam;
