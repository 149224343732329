import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CONTACT_US } from "../../api/constants";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useMediaQuery } from "react-responsive";
import sha256 from "sha256";

const RegisterInterestNew = () => {
  const { pathname } = useLocation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    pageUrl: "",
    ipAddress: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      const response = await axios.post(CONTACT_US, formData);

      if (response.status === 201) {
        setFormData(initialFormData);
        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
        }, 3000);

        localStorage.setItem("formSubmitted", "true");

        // Push custom event to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Website Lead form submit",
        });

        // Push custom event for Criteo
        window.criteo_q = window.criteo_q || [];
        const deviceType = /iPad/.test(navigator.userAgent)
          ? "t"
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
              navigator.userAgent
            )
          ? "m"
          : "d";
        window.criteo_q.push(
          { event: "setAccount", account: "119119" },
          {
            event: "setEmail",
            email: sha256(formData?.email),
            hash_method: "sha256",
          },
          { event: "setSiteType", type: deviceType },
          { event: "setZipcode", zipcode: "##Zip Code##" },
          {
            event: "trackTransaction",
            id: new Date().getTime(),
            item: [
              { id: "1", price: "1", quantity: "1" },
              {
                pageUrl: window.location.href,
                pageName: window.location.pathname,
              },
              //add a line for each additional line in the basket
            ],
          }
        );
        // Push custom event for Criteo End

        ReactPixel.trackCustom("Website Lead form submit", {
          page: pathname,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.status === 400
          ? error.response.data.error ||
            "Validation error. Please check your input."
          : "An error occurred during form submission.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  return (
    <div className="flex flex-col lg:flex-row lg:items-start lg:gap-12 max-w-7xl mx-auto ">
      {/* Image Section */}
      <div className="lg:w-1/2 mb-8 lg:mb-0">
        <img
          src={
            isMobile || isTablet
              ? "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,q_auto,f_auto/v1724751122/xr_media/kqsxb8ecqyxqz1mkkxo6.png"
              : "https://res.cloudinary.com/dkhns25jh/image/upload/q_auto,f_auto/v1724751122/xr_media/kqsxb8ecqyxqz1mkkxo6.png"
          }
          alt="Our Expert Agents"
          className="w-full rounded-lg"
        />
      </div>

      {/* Form Section */}
      <div className="lg:w-1/2 lg:h-full lg:flex lg:flex-col lg:justify-between">
        {/* Header Section */}
        <div className="text-center lg:text-left mb-6 lg:mb-4">
          <h2 className="text-xl font-semibold mb-4">
            Connect with Our Xperts
          </h2>
          <p className="text-gray-600 text-sm">
            Our team of experienced agents is ready to assist you
          </p>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-4 lg:space-y-3">
          <div>
            <input
              type="text"
              name="firstname"
              placeholder="First Name"
              value={formData.firstname}
              onChange={handleChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all text-lg placeholder:text-sm"
              required
            />
          </div>

          <div>
            <input
              type="text"
              name="lastname"
              placeholder="Last Name"
              value={formData.lastname}
              onChange={handleChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all text-lg placeholder:text-sm"
              required
            />
          </div>

          <div>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all text-lg placeholder:text-sm"
              required
            />
          </div>

          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all text-lg placeholder:text-sm"
              required
            />
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="mx-auto flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span>Submitting...</span>
              </>
            ) : (
              <>
                <span className="font-medium">Register Your Interest</span>
                <svg
                  className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </>
            )}
          </button>

          <p className="text-center text-sm text-gray-600">
            By clicking Submit, you agree to our{" "}
            <a
              href="/privacy-policy/"
              className="text-blue-600 hover:underline"
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy/"
              className="text-blue-600 hover:underline"
            >
              Privacy Policy
            </a>
          </p>
        </form>

        {isSubmitted && (
          <div className="fixed inset-0 flex items-center justify-center bg-black/40 backdrop-blur-sm animate-fadeIn">
            <div className="bg-white p-8 rounded-2xl shadow-xl w-[90%] max-w-md text-center transform animate-slideUp">
              <div className="mb-4">
                <svg
                  className="w-16 h-16 mx-auto text-green-500 animate-checkmark"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h3 className="text-2xl font-medium text-gray-900 mb-3">
                Thank you!
              </h3>
              <p className="text-gray-600">
                We've received your submission and will be in touch soon.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterInterestNew;
