import React from "react";
import { AiFillStar } from "react-icons/ai";

export default function GoogleReviews({ data }) {
  const [expandedReviews, setExpandedReviews] = React.useState({});

  const toggleReview = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-3xl font-normal flex items-center justify-center">
        <span>
          Our <span className="text-[#4285F4]">G</span>
          <span className="text-[#EA4335]">o</span>
          <span className="text-[#FBBC05]">o</span>
          <span className="text-[#4285F4]">g</span>
          <span className="text-[#34A853]">l</span>
          <span className="text-[#EA4335]">e</span> Reviews
        </span>
      </h2>
      <span className="text-sm text-gray-500 mt-2">What Our Customers Say</span>
      <div className="flex items-center justify-center mt-6">
        <div className="flex items-center gap-2">
          <div className="flex text-yellow-400 text-xl">
            {"★".repeat(Math.round(data?.google_overall_rating))}
          </div>
          <span className="text-xl font-medium">
            {data?.google_overall_rating}
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 py-8 px-4">
          {data?.google_review_cards?.map((review, index) => {
            return (
              <div key={index} className="bg-white p-6 rounded-lg shadow">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                    <span className="text-xl text-gray-600">
                      {review?.client_name?.charAt(0)}
                    </span>
                  </div>
                  <div className="ml-4">
                    <h3 className="font-medium line-clamp-1">
                      {review?.client_name}
                    </h3>
                    <div className="flex text-yellow-400">
                      {[...Array(review?.client_rating)].map((_, index) => (
                        <AiFillStar
                          key={index}
                          className="text-yellow-400 text-sm"
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <p
                    className={`text-gray-600 ${
                      !expandedReviews[index] ? "line-clamp-2" : ""
                    }`}
                  >
                    {review?.client_review}
                  </p>
                  <button
                    onClick={() => toggleReview(index)}
                    className="text-blue-600 text-sm mt-2 hover:underline"
                  >
                    {expandedReviews[index] ? "Show Less" : "View More"}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center">
        <a
          href={data?.view_all_reviews_on_google_link}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
        >
          <svg
            className="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10 5.35 0 9.25-3.67 9.25-9.09 0-1.15-.15-1.81-.15-1.81h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27 3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2z"
              fill="#4285F4"
            />
            <path
              d="M12.1 2C16.56 2 19 4.72 19 4.72l-1.9 1.98c0 0-1.81-1.97-4.9-1.97V2z"
              fill="#EA4335"
            />
            <path
              d="M5 12c0 4.25 3.36 7.27 7.17 7.27 3 0 6.17-1.63 6.5-5.44h-6.51V11.1h9.17c0 0 .15.66.15 1.81C21.49 18.33 17.59 22 12.24 22 6.15 22 2.02 17.05 2.02 12H5z"
              fill="#34A853"
            />
            <path
              d="M12.17 19.27c-3.81 0-7.17-3.02-7.17-7.27H2.02c0 5.05 4.13 10 10.22 10v-2.73z"
              fill="#FBBC05"
            />
          </svg>
          <span className="font-medium">View All Reviews on Google</span>
          <svg
            className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
