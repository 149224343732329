import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ALL_DEVELOPERS_PAGE,
  FETCH_ALL_DEVELOPERS_STRAPI,
} from "../api/constants";
import { Helmet } from "react-helmet";
import { WebsiteContext } from "../context/websiteContext";
import qs from "qs";
import Markdown from "react-markdown";
import FAQs from "../components/FAQs/FAQs";

function AllDevelopers() {
  const [developers, setAllDevelopers] = useState([]);
  const [developerPageData, setDeveloperPageData] = useState([]);
  const { pathname } = useLocation();

  const { setShowAreas } = useContext(WebsiteContext);

  useEffect(() => {
    setShowAreas(true);
  }, []);

  useEffect(() => {
    const fetchDevelopersPageData = async () => {
      const query = qs.stringify({
        populate: "*",
      });
      try {
        const response = await axios.get(`${ALL_DEVELOPERS_PAGE}?${query}`);
        const developersData = response?.data?.data;
        setDeveloperPageData(developersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchDevelopersFromStrapi = async () => {
      const query = qs.stringify({
        populate: ["image"],
      });
      const response = await axios.get(
        `${FETCH_ALL_DEVELOPERS_STRAPI}?${query}`
      );
      const developersData = response?.data?.data;
      setAllDevelopers(developersData);
    };

    fetchDevelopersPageData();
    fetchDevelopersFromStrapi();
  }, []);

  const slides = developers.map((developer, index) => (
    <div
      key={index}
      className="group transform transition-all duration-300 hover:scale-105"
    >
      <Link reloadDocument to={`/developer/${developer?.developer_slug}/`}>
        <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 hover:shadow-lg transition-all duration-300">
          <img
            src={developer?.image?.url}
            alt={`${developer?.image?.alternativeText}`}
            className="w-24 sm:w-28 md:w-32 lg:w-36 xl:w-40 object-contain filter hover:brightness-110 transition-all duration-300"
          />
        </div>
      </Link>
    </div>
  ));

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>

      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
          <div className="space-y-8 md:space-y-12">
            {/* Header Section */}
            <div>
              <h1 className="text-2xl font-normal sm:text-3xl lg:text-4xl  tracking-tight text-gray-900 mb-6">
                {developerPageData?.heading}
              </h1>
              <div className="prose max-w-none text-base sm:text-lg text-gray-600 text-justify leading-relaxed font-normal">
                <Markdown>{developerPageData?.description}</Markdown>
              </div>
            </div>

            {/* Developers Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:gap-8 md:gap-10">
              {slides}
            </div>
          </div>
          <div className="mt-16">
            <FAQs faqs={developerPageData?.faqs} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDevelopers;
