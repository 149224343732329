import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function AgentCardV2({ person }) {
  const handleWhatsAppClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`https://wa.me/${person?.phone}`, "_blank");
  };

  return (
    <div className="relative rounded-lg overflow-hidden bg-gray-900 text-white cursor-pointer">
      <div className="relative w-full">
        <Link reloadDocument to={`/agent/${person?.name_slug}/`}>
          <img
            src={person?.profile_picture.replace(
              "https://res.cloudinary.com/dkhns25jh/image/upload/",
              "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/"
            )}
            alt={`${person?.name}`}
            className="w-full h-full object-contain"
          />
          {/* Top Labels */}
          {/* <div className="absolute top-2 right-2 flex gap-3">
          <span className="px-4 py-1 bg-black text-white rounded-full text-sm font-medium">
            {person?.specialties[0]}
          </span>
        </div> */}
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
          {/* Info Container */}
          <div className="absolute bottom-0 left-0 right-0 p-5">
            <div className="flex justify-between items-end">
              <div className="md:w-[70%] xl:w-[80%]">
                <h2 className="text-lg font-bold mb-1 line-clamp-1">
                  {person?.name}
                </h2>
                <p className="text-sm font-light text-white/90 line-clamp-1">
                  {/* {person?.languages?.map((language) => language).join(", ")} */}
                  {person?.specialties[0]}
                </p>
              </div>

              {/* WhatsApp Button */}
              <button
                onClick={handleWhatsAppClick}
                className="bg-white rounded-full w-10 h-10 flex items-center justify-center transition-transform hover:scale-110"
              >
                <BsWhatsapp className="w-6 h-6 text-[#25D366]" />
              </button>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
