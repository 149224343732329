import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { FETCH_All_DEVELOPERS } from "../../api/constants";
import { SOCIAL_LINKS, CONTACT_INFO, INITIAL_MENU_ITEMS } from "./constants";
import styles from "./NavBarNew.module.css";

export default function NavbarNew() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuItems, setMenuItems] = useState(INITIAL_MENU_ITEMS);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();

  // Add this function to determine if navbar should be solid
  const shouldShowSolidNavbar = () => {
    // Add paths where you want the solid navbar
    const solidNavbarPaths = [
      "/area/",
      "/about-us/",
      "/dubai-properties/",
      "/dubai-properties/for-sale/",
      "/dubai-properties/for-rent/",
      "/living-experience-dubai/",
      "/agent/",
      "/contact-us-dubai-real-estate-agency/",
      "/developer/",
      "/privacy-policy/",
      "/developer/sobha/",
    ];

    // Check for exact matches first
    if (solidNavbarPaths.includes(location.pathname)) {
      return true;
    }

    // Check if the current path matches the dynamic route pattern
    if (location.pathname.startsWith("/dubai-properties/for-sale/")) {
      return true;
    }

    if (location.pathname.startsWith("/real-estate-news/")) {
      return true;
    }

    if (location.pathname.startsWith("/careers/")) {
      return false;
    }

    if (location.pathname.startsWith("/blogs/")) {
      return true;
    }

    if (location.pathname.startsWith("/agent/")) {
      return true;
    }

    if (location.pathname.startsWith("/area/")) {
      return false;
    }

    if (location.pathname.startsWith("/developer/")) {
      return false;
    }

    if (location.pathname.startsWith("/property/")) {
      return false;
    }

    // Check if the current path matches the dynamic route pattern
    if (location.pathname.startsWith("/dubai-properties/for-rent/")) {
      return true;
    }

    if (location.pathname.startsWith("/living-experience-dubai")) {
      return true;
    }

    if (location.pathname.startsWith("/living-experience-dubai/")) {
      return true;
    }

    if (location.pathname.startsWith("/dubai-properties/")) {
      return true;
    }

    if (location.pathname.startsWith("/")) {
      return false;
    }

    return false;
  };

  // Modify scroll handler to consider solid navbar pages
  useEffect(() => {
    const handleScroll = () => {
      if (!shouldShowSolidNavbar()) {
        setIsScrolled(window.scrollY > 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]); // Add location as dependency

  // Fetch developers
  useEffect(() => {
    const fetchDevelopers = async () => {
      try {
        const response = await axios.get(FETCH_All_DEVELOPERS);
        const developersData = response.data.data;

        const developersSubMenu = developersData.map((developer) => ({
          name: developer.developer_name,
          href: `/developer/${developer.developer_slug}/`,
          description: `Explore ${developer.developer_name}${
            developer.developer_name === "Omniyat" ||
            developer.developer_name === "Sobha Realty"
              ? "'s"
              : "'"
          } projects`,
        }));

        setMenuItems((prevItems) =>
          prevItems.map((item) =>
            item.name.toLowerCase() === "developers"
              ? { ...item, subMenu: developersSubMenu }
              : item
          )
        );
      } catch (error) {
        console.error("Error fetching developers:", error);
      }
    };

    fetchDevelopers();
  }, []);

  // Search handler
  const handleSearch = (e) => {
    e.preventDefault();
    // Implement search logic
    console.log("Searching for:", searchQuery);
  };

  return (
    <>
      {/* Add spacer div for solid navbar pages */}
      {shouldShowSolidNavbar() && <div className="h-[50px] lg:h-[70px]"></div>}
      <header
        className={`${styles.navbar} ${
          shouldShowSolidNavbar() ? "fixed w-full top-0 left-0" : ""
        }`}
      >
        {/* Top Bar */}
        <div
          className={`hidden lg:block ${styles.topBar} ${
            isScrolled || shouldShowSolidNavbar()
              ? "h-0 opacity-0"
              : "h-12 opacity-100"
          }`}
        >
          <div className="container mx-auto px-4 h-full flex justify-between items-center bg-transparent text-white">
            <div className="flex items-center space-x-6">
              <a
                href={`tel:${CONTACT_INFO.phone}`}
                className="flex items-center text-white hover:text-blue-500"
              >
                <PhoneIcon className="h-4 w-4 mr-2" />
                <span>{CONTACT_INFO.phone}</span>
              </a>
              <a
                href={`mailto:${CONTACT_INFO.email}`}
                className="flex items-center text-white hover:text-blue-500"
              >
                <EnvelopeIcon className="h-4 w-4 mr-2" />
                <span>{CONTACT_INFO.email}</span>
              </a>
              <div className="flex items-center text-white hover:text-blue-500">
                <MapPinIcon className="h-4 w-4 mr-2" />
                <span>{CONTACT_INFO.address}</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              {SOCIAL_LINKS.map((social) => (
                <a
                  key={social.name}
                  href={social.href}
                  className="text-white hover:text-blue-500 transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`fab fa-${social.icon} text-lg`} />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Main Navigation */}
        <nav
          className={`${styles.mainNav} ${
            isScrolled || shouldShowSolidNavbar()
              ? styles["mainNav-scrolled"]
              : styles["mainNav-transparent"]
          }`}
        >
          <div className="container mx-auto px-4 py-2">
            <div className="flex items-center justify-between">
              {/* Logo */}
              <Link to="/" reloadDocument className="flex-shrink-0">
                <img
                  className={`transition-all duration-300 ${
                    isScrolled ? "h-10 md:h-16" : "h-10 md:h-16"
                  }`}
                  src={
                    isScrolled || shouldShowSolidNavbar()
                      ? "https://res.cloudinary.com/dkhns25jh/image/upload/v1737034152/xr_logo_black.png"
                      : "https://res.cloudinary.com/dkhns25jh/image/upload/v1733821293/xr_media/sqmxma6remiuh1mbv4x7.png"
                  }
                  alt="Xperience Realty"
                />
              </Link>

              {/* Desktop Navigation */}
              <div className="hidden lg:flex items-center space-x-8">
                {menuItems.map((item) => (
                  <Menu as="div" key={item.name} className="relative">
                    {({ open }) => (
                      <>
                        {item.subMenu ? (
                          <Menu.Button
                            className={`flex items-center space-x-1 px-2 py-1 text-base font-medium ${
                              isScrolled || shouldShowSolidNavbar()
                                ? "text-gray-800"
                                : "text-white"
                            } hover:text-blue-500 transition-colors`}
                          >
                            <span>{item.name}</span>
                            <ChevronDownIcon
                              className={`w-4 h-4 transition-transform ${
                                open ? "rotate-180" : ""
                              }`}
                            />
                          </Menu.Button>
                        ) : (
                          <Link
                            reloadDocument
                            to={item.href}
                            className={`flex items-center space-x-1 px-2 py-1 text-base font-medium ${
                              isScrolled || shouldShowSolidNavbar()
                                ? "text-gray-800"
                                : "text-white"
                            } hover:text-blue-500 transition-colors`}
                          >
                            <span>{item.name}</span>
                          </Link>
                        )}

                        {item.subMenu && (
                          <Transition
                            as={Fragment}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-in"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Menu.Items className={styles["dropdown-menu"]}>
                              {item.subMenu.map((subItem) => (
                                <Menu.Item key={subItem.name}>
                                  {({ active }) => (
                                    <Link
                                      reloadDocument
                                      to={subItem.href}
                                      className={styles["dropdown-item"]}
                                    >
                                      <div>
                                        <div className="text-base font-medium text-gray-900">
                                          {subItem.name}
                                        </div>
                                        {subItem.description && (
                                          <p className="text-sm text-gray-500">
                                            {subItem.description}
                                          </p>
                                        )}
                                      </div>
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        )}
                      </>
                    )}
                  </Menu>
                ))}

                {/* Search Button Desktop */}
                {/* <button
                  onClick={() => setSearchOpen(true)}
                  className={`p-2 rounded-full ${
                    isScrolled || shouldShowSolidNavbar()
                      ? "text-gray-800"
                      : "text-white"
                  } hover:text-white transition-colors`}
                >
                  <MagnifyingGlassIcon className="w-5 h-5" />
                </button> */}
              </div>

              {/* Mobile buttons - Updated with ml-auto */}
              <div className="flex items-center justify-end lg:hidden ml-auto">
                {/* <button
                  onClick={() => setSearchOpen(true)}
                  className="p-2 text-gray-600 mr-2"
                >
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </button> */}
                <button className="p-2" onClick={() => setMobileMenuOpen(true)}>
                  <Bars3Icon
                    className={`w-8 h-8 ${
                      isScrolled || shouldShowSolidNavbar()
                        ? "text-gray-800"
                        : "text-white"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </nav>

        {/* Search Modal */}
        <Transition.Root show={searchOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSearchOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <Dialog.Panel className={styles["search-modal"]}>
                  <div className="container mx-auto px-4 py-4">
                    <form onSubmit={handleSearch} className="relative">
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search properties, areas, or developers..."
                        className="w-full py-3 pl-12 pr-4 text-gray-900 placeholder-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold"
                      />
                      <MagnifyingGlassIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    </form>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Mobile Menu */}
        <Transition show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="min-h-full bg-white p-4">
                <div className="flex items-center justify-between">
                  <Link reloadDocument to="/" className="flex-shrink-0">
                    <img
                      className="h-16 w-auto"
                      src="https://res.cloudinary.com/dkhns25jh/image/upload/v1737034152/xr_logo_black.png"
                      alt="Xperience Realty"
                    />
                  </Link>
                  <button
                    onClick={() => setMobileMenuOpen(false)}
                    className="p-2 text-gray-600"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>

                <div className="mt-6 space-y-4">
                  {menuItems.map((item) => (
                    <div key={item.name} className="space-y-2">
                      {item.href ? (
                        <Link
                          reloadDocument
                          to={item.href}
                          className="block px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50 rounded-md"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <>
                          <div className="px-3 py-2 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                          {item.subMenu && (
                            <div className="pl-6 space-y-1">
                              {item.subMenu.map((subItem) => (
                                <Link
                                  reloadDocument
                                  key={subItem.name}
                                  to={subItem.href}
                                  className="block px-3 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-md"
                                  onClick={() => setMobileMenuOpen(false)}
                                >
                                  {subItem.name}
                                </Link>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>

                <div className="mt-6 pt-6 border-t border-gray-200">
                  <div className="space-y-4">
                    <a
                      href={`tel:${CONTACT_INFO.phone}`}
                      className="flex items-center text-gray-600 hover:text-blue-500"
                    >
                      <PhoneIcon className="w-5 h-5 mr-3" />
                      <span>{CONTACT_INFO.phone}</span>
                    </a>
                    <a
                      href={`mailto:${CONTACT_INFO.email}`}
                      className="flex items-center text-gray-600 hover:text-blue-500"
                    >
                      <EnvelopeIcon className="w-5 h-5 mr-3" />
                      <span>{CONTACT_INFO.email}</span>
                    </a>
                  </div>

                  <div className="mt-6 flex space-x-6">
                    {SOCIAL_LINKS.map((social) => (
                      <a
                        key={social.name}
                        href={social.href}
                        className="text-gray-400 hover:text-blue-500"
                      >
                        <i className={`fab fa-${social.icon} text-xl`} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      </header>
    </>
  );
}
