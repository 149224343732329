import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Outlet,
} from "react-router-dom";

import NotFound from "./components/NotFound";
import { ToastContainer } from "react-toastify";
import ScrollTriggerModal from "./components/ScrollTriggerModal";
import LetXpertsInput from "./components/LetXpertsInput";
import AllDevelopers from "./pages/AllDevelopers";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Developers from "./pages/Developers";
import XrTeam from "./pages/XrTeam";
import AllNewsInsights from "./pages/AllNewsInsights";
import RenderContent from "./components/RenderContent";
import NavbarNew from "./components/NavBarNew/NavBarNew";
import Guides from "./pages/Guides";
import AllGuides from "./pages/AllGuides";
import AllCommunitiesNew from "./pages/AllCommunitiesNew";
import AboutUsNew from "./pages/AboutUsNew";
import FooterNew from "./components/FooterNew/FooterNew";
import { WebsiteProvider } from "./context/websiteContext";
import FaqsPage from "./pages/FaqsPage";
import PropertiesForSale from "./pages/PropertiesForSale";
import LivingExperienceDubai from "./pages/LivingExperienceDubai";
import LivingExperienceDubaiType from "./pages/LivingExperienceDubaiType";
import AllProjectsNew from "./components/AllProjectsNew/AllProjectsNew";
import OurCommunitiesNew from "./components/OurCommunitiesNew/OurCommunitiesNew";
import AgentDetailNew from "./components/AgentDetailNew/AgentDetailNew";
import PrivacyPolicyNew from "./components/PrivacyPolicyNew/PrivacyPolicyNew";
import CareersNew from "./pages/CareersNew";
import ProjectDetailsNew from "./components/ProjectDetailsNew/ProjectDetailsNew";
import ChatBots from "./components/ChatBots/ChatBots";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function WithPopup() {
  return (
    <>
      <Outlet />
      <ScrollTriggerModal triggerPoints={[0.2]}>
        <LetXpertsInput theme="light" />
      </ScrollTriggerModal>
      <ChatBots />
    </>
  );
}

function App() {
  return (
    <Router>
      <WebsiteProvider>
        <Wrapper>
          {/* <Navbar /> */}
          <NavbarNew />
          {/* <Suspense fallback={<Shimmer />}> */}
          <Routes>
            <Route element={<WithPopup />}>
              <Route path="/" exact element={<Home />} />
              <Route path="/about-us/" element={<AboutUsNew />} />
              <Route
                path="/contact-us-dubai-real-estate-agency/"
                element={<ContactUs />}
              />
              <Route path="/developer/" element={<AllDevelopers />} />
              {/* <Route path="/label/:id" element={<Developers />} /> */}
              <Route path="/developer/:id/" element={<Developers />} />
              {/* <Route path="/meet-the-xr/" element={<XrTeam />} /> */}
              <Route path="/agent/" element={<XrTeam />} />
              <Route path="/dubai-properties/" element={<AllProjectsNew />} />
              <Route
                path="/dubai-properties/:saleType/:type/"
                element={<PropertiesForSale />}
              />
              <Route
                path="/dubai-properties/:type/"
                element={<AllProjectsNew />}
              />
              <Route
                path="/living-experience-dubai/"
                element={<LivingExperienceDubai />}
              />
              <Route
                path="/living-experience-dubai/:type/"
                element={<LivingExperienceDubaiType />}
              />
              <Route path="/area/" element={<AllCommunitiesNew />} />
              <Route path="/real-estate-news/" element={<AllNewsInsights />} />
              <Route path="/blogs/" element={<AllNewsInsights />} />
              <Route path="/faqs/" element={<FaqsPage />} />

              <Route path="/guides/" element={<AllGuides />} />
              <Route path="/guides/:slug/" element={<Guides />} />
              <Route
                path="/real-estate-news/:title/"
                element={<RenderContent />}
              />
              <Route path="/blogs/:title/" element={<RenderContent />} />

              <Route path="/notfound/" element={<NotFound />} />

              <Route path="/property/:title/" element={<ProjectDetailsNew />} />
              {/* <Route path="/propertytype/:type/" element={<AllProjects />} /> */}

              <Route path="/area/:title/" element={<OurCommunitiesNew />} />
              <Route path="/agent/:agentName/" element={<AgentDetailNew />} />
              <Route path="/privacy-policy/" element={<PrivacyPolicyNew />} />
              {/* <Route
                path="/customer-reviews/"
                element={<ReviewsComponent viewAll={true} />}
              /> */}
            </Route>
            <Route path="/careers/" element={<CareersNew />} />
            <Route path="/:title/" element={<NotFound />} />
          </Routes>
          {/* </Suspense> */}
        </Wrapper>
        {/* <Footer /> */}
        <FooterNew />
        <ToastContainer />
      </WebsiteProvider>
    </Router>
  );
}

export default App;
