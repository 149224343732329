import React from "react";
import { Link } from "react-router-dom";

const toTitleCase = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const NewsAndInsightsCardV3 = ({ isLoading, blog }) => {
  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        {/* Image Container Shimmer */}
        <div className="relative w-full aspect-[16/9] rounded-lg overflow-hidden bg-gray-200 animate-pulse" />

        {/* Content Section */}
        <div className="flex flex-col gap-4">
          {/* Title Shimmer - Two lines */}
          <div className="space-y-2">
            <div className="h-5 bg-gray-200 rounded animate-pulse w-full"></div>
            <div className="h-5 bg-gray-200 rounded animate-pulse w-4/5"></div>
          </div>

          {/* Date Shimmer */}
          <div className="h-4 bg-gray-200 rounded animate-pulse w-20"></div>

          {/* Read More Button Shimmer */}
          <div className="h-5 bg-gray-200 rounded animate-pulse w-24"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {/* Image Container */}
      <div className="relative w-full aspect-[16/9] rounded-lg overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${blog?.featured_image})`,
          }}
        />
      </div>

      {/* Content Section */}
      <div className="flex flex-col gap-4">
        {/* Title */}
        <h3 className="text-base font-semibold text-gray-900">
          {toTitleCase(blog?.title)}
        </h3>

        {/* Date and Views */}
        <div className="flex items-center gap-4 text-sm text-gray-500">
          <span>{new Date(blog?.publish_date).toLocaleDateString()}</span>
          {/* <div className="flex items-center gap-1">
            <svg
              className="w-4 h-4"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            <span>14</span>
          </div> */}
        </div>

        {/* Preview Text */}
        {/* <p className="text-gray-600">
          2024 was a record-breaking year for Dubai's real estate market, and I
          am pleased to share the remarkable mil...
        </p> */}

        {/* Read More Button */}
        {/* Old Link */}
        {/* <Link
          reloadDocument
          to={`${
            blog?.category.toLowerCase() === "blog"
              ? "/blogs"
              : "/real-estate-news"
          }/${blog?.slug}/`}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors"
        >
          <span className="text-sm font-medium">Read More</span>
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
              fill="currentColor"
            />
          </svg>
        </Link> */}

        {/* New Link */}
        <Link
          reloadDocument
          to={`/${blog?.slug}`}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors"
        >
          <span className="text-sm font-medium">Read More</span>
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
              fill="currentColor"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default NewsAndInsightsCardV3;
