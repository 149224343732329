import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { WebsiteContext } from "../../context/websiteContext";
import { NEWSLETTER_SUBSCRIBERS } from "../../api/constants";

const keyframes = `
  @keyframes slideIn {
    from {
      transform: translateX(2rem);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes infiniteScroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const SuccessPopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
      <div
        className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      <div
        className="bg-white rounded-lg p-6 shadow-xl relative z-10 max-w-md w-full transform transition-all animate-slideIn"
        style={{ animation: "slideIn 0.3s ease-out" }}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
            <svg
              className="h-6 w-6 text-green-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Successfully Subscribed!
          </h3>
          <p className="text-sm text-gray-500">
            Thank you for subscribing to our newsletter. You'll receive our
            latest updates straight to your inbox.
          </p>
        </div>
      </div>
    </div>
  );
};

export default function FooterNew() {
  const { showAreas } = useContext(WebsiteContext);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [areas, setAreas] = useState([
    { area_name: "Dubai Marina", location: "/area/dubai-marina/" },
    { area_name: "Palm Jumeirah", location: "/area/palm-jumeirah/" },
    { area_name: "Downtown Dubai", location: "/area/downtown-dubai/" },
    { area_name: "Business Bay", location: "/area/business-bay/" },
    { area_name: "Jumeirah Beach Residence", location: "/area/jbr/" },
    { area_name: "Dubai Hills Estate", location: "/area/dubai-hills-estate/" },
    { area_name: "Arabian Ranches", location: "/area/arabian-ranches/" },
    {
      area_name: "Dubai Creek Harbour",
      location: "/area/dubai-creek-harbour/",
    },
  ]);

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = keyframes;
    document.head.appendChild(style);

    return () => {
      // Check if the style element is still in the document
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setIsLoading(true);

    try {
      const response = await fetch(NEWSLETTER_SUBSCRIBERS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: { email } }),
      });

      if (response.ok) {
        setShowSuccessPopup(true);
        setEmail("");
      } else {
        setMessage("Subscription failed. Try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value || "");
  };

  const propertyCategories = {
    "Dubai Properties": [
      { title: "About XR", link: "/about-us/" },
      { title: "Join XR", link: "/careers/" },
      { title: "Contact Us", link: "/contact-us-dubai-real-estate-agency/" },
      {
        title: "Location Map",
        link: "https://www.google.com/maps/place/Xperience%E2%80%8B+Realty/@25.1143597,55.2437603,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f69742ae364c3:0xf5f30b07708195fd!8m2!3d25.1143549!4d55.2463352!16s%2Fg%2F11ty7cwknc?entry=tts&shorturl=1",
      },
      { title: "XR Blog", link: "/blogs/" },
      { title: "Dubai FAQs", link: "/faqs/" },
      // { title: "Mortgage Calculator", link: "/calculator/" },
    ],
    "Dubai Properties for Sale": [
      {
        title: "Dubai Penthouse for Sale",
        link: "/dubai-properties/for-sale/type-penthouse/",
      },
      {
        title: "Dubai Mansion for Sale",
        link: "/dubai-properties/for-sale/type-mansion/",
      },
      {
        title: "Dubai Apartment for Sale",
        link: "/dubai-properties/for-sale/type-apartment/",
      },
      {
        title: "Dubai Villa for Sale",
        link: "/dubai-properties/for-sale/type-villa/",
      },
      {
        title: "Houses for Sale in Dubai",
        link: "/dubai-properties/for-sale/type-house/",
      },
      { title: "Plot in Dubai", link: "/dubai-properties/for-sale/type-plot/" },
      // {
      //   title: "Office for sale in Dubai",
      //   link: "/dubai-properties/for-sale/type-office/",
      // },
      {
        title: "Buy Ready Apartments in Dubai",
        link: "/dubai-properties/for-sale/type-ready-apartments/",
      },
      {
        title: "Buy Ready Villas in Dubai",
        link: "/dubai-properties/for-sale/type-ready-villas/",
      },
      {
        title: "Townhouse for Sale in Dubai",
        link: "/dubai-properties/for-sale/type-townhouse/",
      },
      {
        title: "Buy Ready Townhouses in Dubai",
        link: "/dubai-properties/for-sale/type-ready-townhouses/",
      },
      {
        title: "Lands in Dubai for Sale",
        link: "/dubai-properties/for-sale/type-lands/",
      },
    ],
    "Beachfront & Waterfront Properties": [
      {
        title: "Beachfront Properties for Sale",
        link: "/dubai-properties/for-sale/type-beachfront/",
      },
      {
        title: "Beachfront Properties for Rent",
        link: "/dubai-properties/for-rent/type-beachfront/",
      },
      {
        title: "Waterfront Properties for Sale",
        link: "/dubai-properties/for-sale/type-waterfront/",
      },
      {
        title: "Waterfront Properties for Rent",
        link: "/dubai-properties/for-rent/type-waterfront/",
      },
      {
        title: "Beachfront Villas for Sale",
        link: "/dubai-properties/for-sale/type-beachfront-villas/",
      },
      {
        title: "Beachfront Villas for Rent",
        link: "/dubai-properties/for-rent/type-beachfront-villas/",
      },
      {
        title: "Beachfront Apartments for Sale",
        link: "/dubai-properties/for-sale/type-beachfront-apartments/",
      },
      {
        title: "Beachfront Apartments for Rent",
        link: "/dubai-properties/for-rent/type-beachfront-apartments/",
      },
    ],
    "Luxury Properties": [
      // {
      //   title: "Luxury Apartments For Sale",
      //   link: "/dubai-properties/for-sale/type-luxury-apartments/",
      // },
      {
        title: "Luxury Villas For Sale",
        link: "/dubai-properties/for-sale/type-luxury-villas/",
      },
      {
        title: "Luxury Homes For Sale",
        link: "/dubai-properties/for-sale/type-luxury-homes/",
      },
      {
        title: "Luxury Penthouses For Sale",
        link: "/dubai-properties/for-sale/type-luxury-penthouses/",
      },
      {
        title: "Luxury Apartments For Rent",
        link: "/dubai-properties/for-rent/type-luxury-apartments/",
      },
      {
        title: "Luxury Villas For Rent",
        link: "/dubai-properties/for-rent/type-luxury-villas/",
      },
      {
        title: "Luxury Homes For Rent",
        link: "/dubai-properties/for-rent/type-luxury-homes/",
      },
      {
        title: "Luxury Penthouses For Rent",
        link: "/dubai-properties/for-rent/type-luxury-penthouses/",
      },
    ],

    "Off Plan Property Dubai": [
      {
        title: "Buy Off plan Apartments in Dubai",
        link: "/dubai-properties/for-sale/type-off-plan-apartments/",
      },
      {
        title: "Buy Off plan Villas in Dubai",
        link: "/dubai-properties/for-sale/type-off-plan-villas/",
      },
      // {
      //   title: "Buy Off plan Townhouses in Dubai",
      //   link: "/dubai-properties/for-sale/type-off-plan-townhouses/",
      // },
      {
        title: "Off plan Projects in Dubai",
        link: "/dubai-properties/for-sale/type-off-plan-projects/",
      },
      {
        title: "Off plan Villa Projects in Dubai",
        link: "/dubai-properties/for-sale/type-off-plan-villa-projects/",
      },
      {
        title: "Off plan Apartment Projects in Dubai",
        link: "/dubai-properties/for-sale/type-off-plan-apartment-projects/",
      },
      {
        title: "Off plan Townhouse Projects in Dubai",
        link: "/dubai-properties/for-sale/type-off-plan-townhouse-projects/",
      },
    ],
    "Dubai Living Experiences": [
      { title: "Dubai Living", link: "/living-experience-dubai/" },
      { title: "Beachfront", link: "/living-experience-dubai/beachfront/" },
      { title: "Waterfront", link: "/living-experience-dubai/waterfront/" },
      { title: "Downtown", link: "/living-experience-dubai/downtown/" },
      { title: "Golf Course", link: "/living-experience-dubai/golf-course/" },
      {
        title: "Island Living",
        link: "/living-experience-dubai/island-living/",
      },
      {
        title: "Green Nature Living",
        link: "/living-experience-dubai/green-nature/",
      },
    ],
    "Projects In Dubai": [
      {
        title: "Ready Villa Projects in Dubai",
        link: "/dubai-properties/for-sale/type-ready-villa-projects/",
      },
      {
        title: "Ready Apartment Projects in Dubai",
        link: "/dubai-properties/for-sale/type-ready-apartment-projects/",
      },
      {
        title: "Ready Townhouse Projects in Dubai",
        link: "/dubai-properties/for-sale/type-ready-townhouse-projects/",
      },
      {
        title: "Luxury Projects in Dubai",
        link: "/dubai-properties/for-sale/type-luxury-projects/",
      },
      {
        title: "Ultra Luxury Projects in Dubai",
        link: "/dubai-properties/for-sale/type-ultra-luxury-projects/",
      },
    ],
  };

  return (
    <footer className="text-gray-300 ">
      {/* Conditional Card Ends */}
      {showAreas && (
        <div className="bg-white text-white py-12 w-full md:mx-auto md:w-[95%] xl:w-[1200px] lg:mx-auto ">
          <div className="container rounded-lg bg-black py-12 mx-auto px-4 w-full md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
            <div className="min-h-auto w-full bg-black flex flex-col items-center justify-center text-center rounded-lg">
              <h2 className="text-2xl font-bold text-white mb-4">
                Browse properties by Dubai areas
              </h2>

              <p className="text-gray-400 text-sm md:text-base mb-8">
                Explore properties in Dubai's most sought-after locations
              </p>

              {/* Wrapper for overflow control */}
              <div className="relative overflow-hidden w-full max-w-4xl mb-8">
                <div
                  className="flex whitespace-nowrap"
                  style={{
                    animation: "infiniteScroll 10s linear infinite",
                  }}
                >
                  {/* Original set */}
                  {areas.map((area, index) => (
                    <Link
                      reloadDocument
                      key={`${area.area_name}-${index}`}
                      to={area.location}
                      className="inline-flex w-fit justify-center items-center gap-2 bg-white text-black px-6 py-2 mx-2 rounded-md transition-all duration-300 hover:bg-gray-100 group"
                    >
                      <span className="font-medium whitespace-nowrap">
                        {area.area_name}
                      </span>
                      <svg
                        className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </Link>
                  ))}
                  {/* Duplicate set for seamless loop */}
                  {areas.map((area, index) => (
                    <Link
                      reloadDocument
                      key={`${area.area_name}-duplicate-${index}`}
                      to={area.location}
                      className="inline-flex w-fit justify-center items-center gap-2 bg-white text-black px-6 py-2 mx-2 rounded-md transition-all duration-300 hover:bg-gray-100 group"
                    >
                      <span className="font-medium whitespace-nowrap">
                        {area.area_name}
                      </span>
                      <svg
                        className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link
                reloadDocument
                to="/area/"
                className="inline-flex w-fit justify-center items-center gap-2 bg-white text-black px-6 py-2 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
              >
                <span className="font-medium">View All Areas</span>
                <svg
                  className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      )}
      {/* Conditional Card Ends */}

      <div className="bg-black">
        {/* Main Footer Content */}
        <div className="container mx-auto px-4 py-12 w-full md:mx-auto md:w-[95%] xl:w-[1200px] lg:mx-auto md:px-6">
          {/* Property Categories Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            {Object.entries(propertyCategories).map(([category, items]) => (
              <div key={category} className="text-center md:text-left">
                <h3 className="text-lg md:text-xl font-semibold mb-4 text-white">
                  {category}
                </h3>
                <ul className="space-y-2">
                  {items.map((item) => (
                    <li key={item.title}>
                      <Link
                        reloadDocument
                        to={item.link}
                        className="text-sm md:text-base hover:text-white transition-colors duration-200"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Newsletter Section */}
          <div className="bg-white rounded-lg p-6 md:p-8 mb-12 ">
            <div className="max-w-2xl mx-auto text-center">
              <h3 className="text-xl md:text-2xl font-bold text-black mb-4">
                Subscribe to Our Newsletter
              </h3>
              <p className="text-sm md:text-base mb-6 text-black">
                Stay updated with the latest property listings and real estate
                news
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col md:flex-row gap-4 justify-center"
              >
                <input
                  type="email"
                  value={email || ""}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="px-4 py-2 rounded-lg bg-black placeholder:text-white text-white flex-grow max-w-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-black hover:bg-gray-800 text-white px-6 py-2 rounded-lg transition-colors duration-200 flex items-center justify-center min-w-[120px]"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </form>
              {showSuccessPopup && (
                <SuccessPopup onClose={() => setShowSuccessPopup(false)} />
              )}
              {message && !message.includes("successfully") && (
                <div className="mt-4 text-sm text-red-600">{message}</div>
              )}
            </div>
          </div>

          {/* Company Info & Social Links */}
          <div className="flex flex-col md:flex-row justify-between items-center border-t border-gray-700 pt-8">
            <div className="mb-6 md:mb-0">
              <img
                src="https://res.cloudinary.com/dkhns25jh/image/upload/v1733821293/xr_media/sqmxma6remiuh1mbv4x7.png"
                alt="Company Logo"
                className="h-12 mb-4"
              />
              <p className="text-sm text-justify md:text-base max-w-md">
                Xperience Realty takes pride in providing our local and overseas
                clients with the highest possible level of service, advice,
                support and assistance with all their property requirements.
              </p>
            </div>

            {/* Social Links */}
            <div className="flex gap-6">
              <a
                href="https://www.youtube.com/@xrealtyae"
                className="hover:text-white transition-colors duration-200"
              >
                <i className="fab fa-youtube text-xl"></i>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61562378023612"
                className="hover:text-white transition-colors duration-200"
              >
                <i className="fab fa-facebook-f text-xl "></i>
              </a>
              <a
                href="https://x.com/xrealty_ae"
                className="hover:text-white transition-colors duration-200"
              >
                <i className="fab fa-twitter text-xl "></i>
              </a>
              <a
                href="https://www.instagram.com/xperience.realty"
                className="hover:text-white transition-colors duration-200"
              >
                <i className="fab fa-instagram text-xl"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/xrealty-ae"
                className="hover:text-white transition-colors duration-200"
              >
                <i className="fab fa-linkedin-in text-xl"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="bg-gray-950 py-4 w-full px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          <div className="container mx-auto px-4 text-center text-sm md:text-base">
            <div className="mb-2">
              <Link reloadDocument to="/privacy-policy/">
                Privacy Policy
              </Link>
              <span className="mx-2">|</span>
              <Link reloadDocument to="/about-us/">
                About Us
              </Link>
              <span className="mx-2">|</span>
              <Link reloadDocument to="/contact-us-dubai-real-estate-agency/">
                Contact Us
              </Link>
              <span className="mx-2">|</span>
              <Link reloadDocument to="/careers/">
                Careers
              </Link>
            </div>
            <p>
              © {new Date().getUTCFullYear()} Xperience Realty Real Estate. All
              Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
