import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import FindYourDreamHome from "../components/FIndYourDreamHome/FindYourDreamHome";
import { GET_CONTENT_LIVING_EXPERIENCE_DUBAI } from "../api/constants";
import qs from "qs";
import axios from "axios";
import { Helmet } from "react-helmet";

const LivingExperienceDubai = () => {
  const [livingExperiences, setLivingExperiences] = useState([]);

  const { pathname } = useLocation();

  useEffect(() => {
    const fetchLivingExperiences = async () => {
      const query = qs.stringify({
        populate: {
          cards: {
            populate: ["image"],
          },
        },
      });
      try {
        const response = await axios.get(
          `${GET_CONTENT_LIVING_EXPERIENCE_DUBAI}?${query}`
        );
        setLivingExperiences(response.data.data);
      } catch (error) {
        console.error("Error fetching living experiences:", error);
      }
    };

    fetchLivingExperiences();
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-2xl font-semibold text-center mb-2">
          {livingExperiences.heading}
        </h1>
        <p className="text-gray-600 text-center mb-12">
          {livingExperiences.subtitle}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
          {livingExperiences?.cards?.map((card, index) => (
            <div key={index} className="flex flex-col">
              <div className="relative h-64 mb-4 rounded-lg overflow-hidden">
                <img
                  src={card?.image?.formats?.medium?.url}
                  alt={card?.image?.alternativeText}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-4">
                {card?.title}
              </h3>
              <div className="flex gap-4">
                <Link
                  reloadDocument
                  to={card?.projectsLink}
                  className="inline-flex w-fit  items-center justify-center gap-2 bg-black text-white px-2 sm:w-[170px] sm:px-4 md:px-2 py-2 sm:py-2 md:py-2 lg:px-2 lg:py-2 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
                >
                  <span className="font-medium text-xs sm:text-base">
                    View Projects
                  </span>
                  <svg
                    className="w-5 h-5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </Link>
                {card?.propertiesLink && (
                  <Link
                    reloadDocument
                    to={card?.propertiesLink}
                    className="inline-flex justify-center w-fit sm:w-[170px] border items-center gap-2 bg-white text-black px-2 sm:px-4 md:px-2 py-2 sm:py-2 md:py-2 lg:px-2 lg:py-2 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
                  >
                    <span className="font-medium text-xs sm:text-base">
                      View Properties
                    </span>
                    <svg
                      className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 mb-10">
          <div className="w-full">
            <FindYourDreamHome />
          </div>
        </div>
      </div>
    </>
  );
};

export default LivingExperienceDubai;
