import React, { useEffect, useState } from "react";
import axios from "axios";
import { PRIVACY_POLICY_NEW } from "../../api/constants";
import Markdown from "react-markdown";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function PrivacyPolicyNew() {
  const [data, setData] = useState(null);
  const { pathname } = useLocation();
  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    const response = await axios.get(PRIVACY_POLICY_NEW);
    setData(response.data.data);
  };

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="bg-white">
        <div className="container mx-auto px-4 mt-14 pb-12">
          <div className="space-y-6 text-justify prose prose-h1:text-2xl prose-h2:text-xl prose-h3:text-lg prose-h4:text-base prose-p:text-sm prose-ul:text-sm prose-li:text-sm max-w-none">
            <Markdown>{data?.privacy_policy}</Markdown>
          </div>
        </div>
      </div>
    </>
  );
}
