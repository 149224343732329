import React from "react";
import { useMediaQuery } from "react-responsive";

export default function CommunitySection({ heading, subheading, communities }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div>
      <h2 className="text-xl font-semibold">{heading}</h2>
      <span className="text-gray-500 text-sm">{subheading}</span>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xs:gap-4 md:grid-cols-2 md:gap-4 lg:grid-cols-3 mt-4">
        {isMobile ? (
          <>
            {communities?.slice(0, 4).map((community, index) => {
              return (
                <div
                  key={index}
                  className="h-[90px] bg-cover bg-center relative"
                  style={{
                    backgroundImage: `url(${community?.area_image?.formats?.small?.url})`,
                  }}
                >
                  <a
                    href={`${community?.area_link}`}
                    className="absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-65 flex items-center no-underline"
                  >
                    <h3 className="text-white line-clamp-1 text-normal font-medium px-6 underline cursor-pointer">
                      {community?.area_name?.toUpperCase()}
                    </h3>
                  </a>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {communities?.slice(0, 6).map((community, index) => {
              return (
                <div
                  key={index}
                  className="h-[90px] bg-cover bg-center relative"
                  style={{
                    backgroundImage: `url(${community?.area_image?.formats?.small?.url})`,
                  }}
                >
                  <a
                    href={`${community?.area_link}`}
                    className="absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-65 flex items-center no-underline"
                  >
                    <h3 className="text-white line-clamp-1 text-normal font-medium px-6 underline cursor-pointer">
                      {community?.area_name?.toUpperCase()}
                    </h3>
                  </a>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
