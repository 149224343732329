import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
export default function ChatBots() {
  return (
    <div className="fixed bottom-0 right-0 m-4  flex flex-row gap-4">
      <div className="bg-white px-4 py-4 rounded-full border border-gray-200 shadow-md hover:scale-105 transition-all duration-300 ">
        {/* Whatsapp Chatbot Icon */}
        <div
          onClick={() => {
            // Put some custom message to the user
            window.open(
              "https://wa.me/+971565467991?text=Hi, I've come across the Xperience Realty website and would like further information about Dubai Real Estate from you.",
              "_blank"
            );
          }}
          className="flex cursor-pointer items-center gap-2 justify-center"
        >
          <BsWhatsapp className="w-8 h-8 md:w-8 md:h-8 text-[#25D366]" />
          <p className="text-sm md:text-base text-black hidden md:block">
            WhatsApp
          </p>
        </div>
      </div>
      <div className="bg-white px-4 py-4 rounded-full border border-gray-200 shadow-md hover:scale-105 transition-all duration-300">
        {/* Telegram Chatbot Icon */}
        <div
          onClick={() => {
            window.open("https://t.me/XperienceRealty_bot", "_blank");
          }}
          className="flex cursor-pointer items-center gap-2 justify-center"
        >
          <BsTelegram className="w-8 h-8 md:w-8 md:h-8 text-[#229ED9]" />
          <p className="text-sm md:text-base text-black hidden md:block">
            Telegram
          </p>
        </div>
      </div>
    </div>
  );
}
