import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";

export default function GoogleReviewsMobile({ data }) {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentReview(
        (prev) => (prev + 1) % data?.google_review_cards?.length
      );
    }, 5000); // Change review every 5 seconds

    return () => clearInterval(timer);
  }, [data?.google_review_cards?.length]);

  return (
    <div className="h-auto p-6 rounded-lg flex flex-col items-center justify-center text-center">
      {/* Rating Header - Updated layout */}
      <div className="w-full flex justify-between items-center mb-6">
        {/* Left side - Rating */}
        <div className="flex items-center gap-2">
          <span className="text-4xl font-normal">
            {data?.google_overall_rating}
          </span>
          <span className="text-gray-500 text-lg">/5.0</span>
        </div>

        {/* Right side - Stars and Review Count */}
        <div className="flex flex-col items-end gap-1">
          <div className="flex gap-1">
            {[...Array(Math.round(data?.google_overall_rating))].map(
              (_, index) => (
                <AiFillStar
                  key={index}
                  className={`text-xl ${
                    index === Math.round(data?.google_overall_rating)
                      ? "text-yellow-400/50"
                      : "text-yellow-400"
                  }`}
                />
              )
            )}
          </div>

          <div className="text-gray-600 text-sm">
            {data?.mobile_google_review_overall_rating_text}
          </div>
        </div>
      </div>

      {/* Review Slideshow */}
      <div className="w-[300px] md:w-[500px] bg-white shadow-md p-4 rounded-lg mb-4 min-h-[120px]">
        <div className="flex flex-col items-center">
          <div className="flex gap-1 mb-2">
            {[
              ...Array(data?.google_review_cards[currentReview]?.client_rating),
            ].map((_, index) => (
              <AiFillStar key={index} className="text-yellow-400 text-sm" />
            ))}
          </div>
          <p className="text-gray-700 mb-2 line-clamp-2">
            {data?.google_review_cards[currentReview]?.client_review}
          </p>
          <div className="text-sm text-gray-500">
            {data?.google_review_cards[currentReview]?.client_name} •{" "}
            {new Date(
              data?.google_review_cards[currentReview]?.review_date
            ).toLocaleDateString()}
          </div>
        </div>
      </div>

      {/* Slideshow Indicators */}
      <div className="flex gap-2 mb-2">
        {data?.google_review_cards?.map((_, index) => (
          <div
            key={index}
            className={`h-1.5 rounded-full transition-all ${
              currentReview === index ? "w-4 bg-black" : "w-1.5 bg-gray-300"
            }`}
          />
        ))}
      </div>

      {/* Google Logo */}
      {/* <span className="text-2xl mb-2">
        <span className="text-[#4285F4]">G</span>
        <span className="text-[#EA4335]">o</span>
        <span className="text-[#FBBC05]">o</span>
        <span className="text-[#4285F4]">g</span>
        <span className="text-[#34A853]">l</span>
        <span className="text-[#EA4335]">e</span>
      </span> */}

      {/* View All Reviews Button */}
      <div className="flex justify-center mt-4">
        <a
          href={data?.view_all_reviews_on_google_link}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
        >
          <svg
            className="w-5 h-5 sm:w-5 sm:h-5 md:w-6 md:h-6"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10 5.35 0 9.25-3.67 9.25-9.09 0-1.15-.15-1.81-.15-1.81h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27 3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2z"
              fill="#4285F4"
            />
            <path
              d="M12.1 2C16.56 2 19 4.72 19 4.72l-1.9 1.98c0 0-1.81-1.97-4.9-1.97V2z"
              fill="#EA4335"
            />
            <path
              d="M5 12c0 4.25 3.36 7.27 7.17 7.27 3 0 6.17-1.63 6.5-5.44h-6.51V11.1h9.17c0 0 .15.66.15 1.81C21.49 18.33 17.59 22 12.24 22 6.15 22 2.02 17.05 2.02 12H5z"
              fill="#34A853"
            />
            <path
              d="M12.17 19.27c-3.81 0-7.17-3.02-7.17-7.27H2.02c0 5.05 4.13 10 10.22 10v-2.73z"
              fill="#FBBC05"
            />
          </svg>
          <span className="font-medium">View All Reviews on Google</span>
          <svg
            className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
