export default function AgentCardShimmer() {
  return (
    <div className="animate-pulse">
      <div className="relative rounded-lg overflow-hidden bg-gray-900 h-[400px]">
        {/* Image placeholder */}
        <div className="w-full h-full bg-gray-200"></div>

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />

        {/* Info Container */}
        <div className="absolute bottom-0 left-0 right-0 p-5">
          <div className="flex justify-between items-end">
            <div className="md:w-[70%] xl:w-[80%] space-y-2">
              {/* Name placeholder */}
              <div className="h-5 bg-gray-200 rounded w-3/4"></div>
              {/* Languages placeholder */}
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>

            {/* WhatsApp button placeholder */}
            <div className="rounded-full w-10 h-10 bg-gray-200"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
