import React from "react";
import { Link } from "react-router-dom";

const FindYourDreamHome = () => {
  return (
    <div className="min-h-[400px] w-full bg-black flex flex-col items-center justify-center px-4 py-12 text-center rounded-lg">
      <h1 className="text-2xl font-bold text-white mb-4">
        Find Your Dream Home
      </h1>

      <p className="text-gray-400 text-sm md:text-base mb-8">
        Let us guide you through the process
      </p>

      <div className="w-full max-w-md space-y-4 flex flex-col items-center">
        <Link
          reloadDocument
          to="/contact-us-dubai-real-estate-agency/"
          className="inline-flex w-full sm:w-[300px] justify-center items-center gap-2 bg-white text-black px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
        >
          <span className="font-medium">Schedule Viewing</span>
          <svg
            className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>

        <Link
          reloadDocument
          to="/agent/"
          className="inline-flex justify-center w-full sm:w-[300px] items-center gap-2 bg-white text-black px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
        >
          <span className="font-medium">Contact Agent</span>
          <svg
            className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default FindYourDreamHome;
