import React from "react";
import { FaGlobe, FaHandshake, FaLevelUpAlt } from "react-icons/fa";

import ContactUsCareersPage from "../components/ContactUsCareersPage";
import { Helmet } from "react-helmet";
import { WEB_URL } from "../api/constants";
import { useLocation } from "react-router-dom";

const contactDetails = [
  {
    label: "Phone",
    value: "+971564080888",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532357/xr_media/tqfxtjyxfy8ivwo4dvou.svg",
    action: "tel:+971564080888",
  },
  {
    label: "Landline",
    value: "+97147049400",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532378/xr_media/uw9yqvpqvpd0bdqxmzuk.svg",
    action: "tel:$+97147049400",
  },
  {
    label: "Email",
    value: "info@xrealty.ae",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532391/xr_media/xp04jixsvn7m6n1zsab7.svg",
    action: "mailto:info@xrealty.ae",
  },
  {
    label: "Office Address",
    value:
      "Office no, 301 & 302, Level 3, Building 1, Park Heights Square 1, Dubai Hills Estate, Dubai, UAE PO Box: 65777",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532407/xr_media/otrx6yvaut5x6luxygtw.svg",
    action:
      "https://www.google.com/maps/place/Xperience%E2%80%8B+Realty/@25.1143597,55.2437603,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f69742ae364c3:0xf5f30b07708195fd!8m2!3d25.1143549!4d55.2463352!16s%2Fg%2F11ty7cwknc?entry=tts&shorturl=1",
  },
];

const formFields = [
  { placeholder: "First Name", type: "text", id: "firstname" },
  { placeholder: "Last Name", type: "text", id: "lastname" },
  { placeholder: "Email address", type: "email", id: "email" },
  { placeholder: "Phone Number", type: "tel", id: "phone" },
];

const WorKWithUsSections = ({ icon, heading, paragraph }) => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 flex-1">
      <div className="flex flex-col gap-4">
        <div className="text-primary">{icon}</div>
        <h2 className="text-xl font-normal text-gray-800">{heading}</h2>
        <p className="text-gray-600 leading-relaxed">{paragraph}</p>
      </div>
    </div>
  );
};

export default function CareersNew() {
  const { pathname } = useLocation();

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "JobPosting",
            name: "Careers | Xperience Realty | Real Estate Dubai",
            description:
              "Join our team of real estate professionals in Dubai. Explore career opportunities with Xperience Realty.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>

      <div
        className="relative mt-0 bg-cover bg-no-repeat bg-center h-[60vh] xl:h-[80vh]"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dkhns25jh/image/upload/q_auto,f_auto/v1724751122/xr_media/kqsxb8ecqyxqz1mkkxo6.png')",
        }}
      >
        <div className="absolute inset-0 bg-black/50" />
        <div className="relative z-10 container mx-auto px-4 h-full flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-4xl font-normal text-white mb-4">
            Join Our Team
          </h1>
          <p className="text-lg md:text-xl font-light text-white max-w-2xl">
            Join our team of real estate professionals in Dubai. Explore career
            opportunities with Xperience Realty.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-normal text-gray-900 text-center mb-12">
          Why Work With Us?
        </h2>
        <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-8">
          <WorKWithUsSections
            icon={<FaLevelUpAlt size={40} />}
            heading="Industry-Leading Growth & Learning"
            paragraph={
              "XR Academy training gives exclusive access to advanced skills, hands-on mentorship, and insights from the creators of Xperience Realty who were once the market heroes. Make yourself lead in this flourishing industry by making continuous learning a culture."
            }
          />
          <WorKWithUsSections
            icon={<FaGlobe size={40} />}
            heading="Global Clients & Expansive Networking"
            paragraph={
              "Being a leading real estate agency in Dubai, Xperience Realty opens the door to a global client base. Work with international investors, high-net-worth individuals, and global buyers, being a matchmaker that assures a rewarding future in Dubai."
            }
          />
          <WorKWithUsSections
            icon={<FaHandshake size={40} />}
            heading="Alliance with Leading Developers"
            paragraph={
              "Collaborate with Dubai's most prestigious real estate developers in Dubai. As the top real estate brokers in Dubai, we exclusively represent premium projects from the industry giants by giving you access to deal with the city's most iconic developments."
            }
          />
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-12">
          <div className="bg-white rounded-xl shadow-sm p-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-2">
              Join Our Team
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Are you ready to take the next step in your career?
              <br /> Join our team of passionate professionals and be part of a
              dynamic, innovative environment. Whether you’re a seasoned expert
              or just starting out, we’d love to hear from you. Feel free to
              reach out to us via email, phone, or by completing our application
              form for current opportunities or general inquiries about working
              with us.
            </p>

            <ContactUsCareersPage
              formFields={formFields}
              contactDetails={contactDetails}
            />
          </div>

          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <iframe
              title="XR location"
              width="100%"
              height="100%"
              className="min-h-[400px]"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=xperiance%20realty+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
