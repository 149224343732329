import React from "react";

const CommunityShimmer = () => {
  return (
    <div className="animate-pulse">
      {/* Header */}
      <div>
        <div className="h-7 w-64 bg-gray-200 rounded mb-1"></div>
        <div className="h-5 w-80 bg-gray-200 rounded"></div>
      </div>

      {/* Grid of community cards */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xs:gap-4 md:grid-cols-2 md:gap-4 lg:grid-cols-3 mt-4">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="h-[90px] bg-gray-200 rounded relative">
            <div className="absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-10 flex items-center">
              <div className="h-5 w-40 bg-gray-200 rounded mx-6"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunityShimmer;
