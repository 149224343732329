import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { WebsiteContext } from "../context/websiteContext";
import NotFound from "../components/NotFound";
import axios from "axios";
import { GET_CONTENT_LIVING_EXPERIENCE_DUBAI_TYPE } from "../api/constants";
import qs from "qs";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Helmet } from "react-helmet";

const livingexperiencesMap = {
  beachfront: "Beachfront",
  waterfront: "Waterfront",
  downtown: "Downtown",
  "golf-course": "Golf Course",
  "island-living": "Island Living",
  "green-nature": "Green Nature Living",
};

const LivingExperienceDubaiType = () => {
  const { type } = useParams();
  const title = livingexperiencesMap[type];
  const { pathname } = useLocation();

  const { setShowAreas } = useContext(WebsiteContext);

  const [livingExperienceType, setLivingExperienceType] = useState([]);

  useEffect(() => {
    if (title) {
      setShowAreas(true);
    }
  }, [setShowAreas, title]);

  // Update state variables
  const [rotatingCards, setRotatingCards] = useState({});
  const [currentTypeIndices, setCurrentTypeIndices] = useState({});

  // Update these constants for different screen sizes
  const initialVisibleCount = {
    mobile: 4,
    tablet: 10,
    desktop: 20,
  };

  // Update state to track screen size
  const [screenSize, setScreenSize] = React.useState("mobile");
  const [visibleProperties, setVisibleProperties] = React.useState(
    initialVisibleCount.mobile
  );

  // Add screen size detection
  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        // lg breakpoint
        setScreenSize("desktop");
        setVisibleProperties(
          Math.min(
            initialVisibleCount.desktop,
            livingExperienceType[0]?.properties?.length
          )
        );
      } else if (width >= 768) {
        // md breakpoint
        setScreenSize("tablet");
        setVisibleProperties(
          Math.min(
            initialVisibleCount.tablet,
            livingExperienceType[0]?.properties?.length
          )
        );
      } else {
        setScreenSize("mobile");
        setVisibleProperties(
          Math.min(
            initialVisibleCount.mobile,
            livingExperienceType[0]?.properties?.length
          )
        );
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    livingExperienceType,
    initialVisibleCount.desktop,
    initialVisibleCount.mobile,
    initialVisibleCount.tablet,
  ]);

  // Update view handlers
  const handleViewMore = () => {
    setVisibleProperties(livingExperienceType[0]?.properties?.length);
  };

  const handleViewLess = () => {
    const currentInitialCount = initialVisibleCount[screenSize];
    setVisibleProperties(currentInitialCount);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to handle type rotation
  const rotateType = React.useCallback(
    (cardIndex) => {
      const variants =
        livingExperienceType[0]?.properties[cardIndex]?.property_variants;
      // Only rotate if there are multiple types for this card
      if ((variants?.length || 0) > 1) {
        setRotatingCards((prev) => ({ ...prev, [cardIndex]: true }));
        setTimeout(() => {
          setCurrentTypeIndices((prev) => ({
            ...prev,
            [cardIndex]: ((prev[cardIndex] || 0) + 1) % variants.length,
          }));
          setRotatingCards((prev) => ({ ...prev, [cardIndex]: false }));
        }, 750);
      }
    },
    [livingExperienceType]
  );

  // Set up interval for rotation only if there are multiple types
  React.useEffect(() => {
    const intervals = livingExperienceType[0]?.properties?.map(
      (card, index) => {
        if ((card?.property_variants?.length || 0) > 1) {
          return setInterval(() => rotateType(index), 4000);
        }
        return null;
      }
    );

    return () =>
      intervals?.forEach((interval) => interval && clearInterval(interval));
  }, [livingExperienceType, rotateType]);

  useEffect(() => {
    const fetchLivingExperienceType = async () => {
      const query = qs.stringify({
        filters: {
          living_experience_type: {
            slug: {
              $eq: type,
            },
          },
        },
        populate: {
          properties: {
            populate: {
              card_image: {
                populate: "*",
              },
              property_variants: {
                populate: "*",
              },
            },
          },
        },
      });

      const response = await axios.get(
        `${GET_CONTENT_LIVING_EXPERIENCE_DUBAI_TYPE}?${query}`
      );
      setLivingExperienceType(response.data.data);
    };
    fetchLivingExperienceType();
  }, [setLivingExperienceType, type]);

  if (!title) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="mt-9 md:mt-16">
        <div className="container mx-auto px-4 xl:w-[1200px] ">
          <h1 className="text-center text-2xl font-bold mb-8">
            {livingExperienceType[0]?.heading}
          </h1>
          <div
            className="text-base mx-auto w-[100%] text-justify leading-relaxed mb-4 prose  prose-lg prose-blue max-w-none  
              prose-headings:text-xl prose-headings:font-bold prose-headings:mb-4
              prose-p:text-base md:prose-p:text-base
              prose-p:text-justify
              prose-a:text-blue-600 prose-a:hover:underline
              prose-strong:font-semibold
              prose-ul:text-base
              prose-ul:space-y-2 "
          >
            <Markdown rehypePlugins={[rehypeRaw]}>
              {livingExperienceType[0]?.description}
            </Markdown>
          </div>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {livingExperienceType[0]?.properties
              ?.slice(0, visibleProperties)
              .map((property, index) => (
                <Link
                  reloadDocument
                  to={property?.url}
                  key={index}
                  className="relative w-full h-[300px] rounded-lg overflow-hidden"
                >
                  {/* Background Image */}
                  <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${property?.card_image?.formats?.medium?.url})`,
                    }}
                  >
                    {/* Overlay for better text visibility */}
                    <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                  </div>

                  {/* Top Labels */}
                  <div className="absolute top-4 right-4 flex gap-3">
                    <span className="px-4 py-1 bg-black text-white rounded-full text-sm font-medium">
                      {property?.property_status}
                    </span>
                    <span className="px-4 py-1 bg-white bg-opacity-90 rounded-full text-sm font-medium">
                      {property?.price}
                    </span>
                  </div>

                  {/* Bottom Content */}
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent">
                    <h3 className="text-white text-xl font-medium mb-2">
                      {property?.name}
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      <span
                        className={`px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm
                    transition-all duration-1000 ease-in-out
                    ${
                      (property?.property_variants?.length || 0) > 1 &&
                      rotatingCards[index]
                        ? "animate-rotate-x"
                        : ""
                    }`}
                        style={{
                          transformStyle: "preserve-3d",
                          perspective: "1000px",
                        }}
                      >
                        {
                          property?.property_variants?.[
                            currentTypeIndices[index] || 0
                          ]?.bedrooms
                        }{" "}
                        BR{" "}
                        {property?.property_variants?.[
                          currentTypeIndices[index] || 0
                        ]?.name?.toUpperCase()}
                      </span>
                      <span className="px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm">
                        {property?.developer_name}
                      </span>
                      <span className="px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm">
                        {property?.community_name}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
          </div>

          {/* View Controls - Responsive for all screen sizes */}
          <div className="mt-8 text-center">
            {((screenSize === "mobile" &&
              livingExperienceType[0]?.properties?.length >
                initialVisibleCount.mobile) ||
              (screenSize === "tablet" &&
                livingExperienceType[0]?.properties?.length >
                  initialVisibleCount.tablet) ||
              (screenSize === "desktop" &&
                livingExperienceType[0]?.properties?.length >
                  initialVisibleCount.desktop)) &&
            visibleProperties < livingExperienceType[0]?.properties?.length ? (
              <button
                onClick={handleViewMore}
                className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
              >
                View More Properties
              </button>
            ) : (
              visibleProperties > initialVisibleCount[screenSize] && (
                <button
                  onClick={handleViewLess}
                  className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
                >
                  Show Less
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LivingExperienceDubaiType;
