import React from "react";
import ReactPlayer from "react-player/lazy";

export default function HeroVideo2({ videoData }) {
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [isMuted, setIsMuted] = React.useState(true);
  const [isButtonVisible, setIsButtonVisible] = React.useState(false);
  const [showInitialTitle, setShowInitialTitle] = React.useState(false);
  const [showTitle, setShowTitle] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const playerRef = React.useRef(null);
  let hideTimeout;
  const [showModal, setShowModal] = React.useState(false);

  const showButton = () => {
    setIsButtonVisible(true);
    clearTimeout(hideTimeout);
    hideTimeout = setTimeout(() => {
      setIsButtonVisible(false);
    }, 5000);
  };

  const handleInfoClick = () => {
    if (window.innerWidth < 1024) {
      setShowModal(true);
    } else {
      setShowTitle(true);
      clearTimeout(hideTimeout);
      hideTimeout = setTimeout(() => {
        setShowTitle(false);
      }, 5000);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    showButton();
    setIsPlaying(true);
    setTimeout(() => {
      setShowInitialTitle(false);
    }, 5000);
    return () => clearTimeout(hideTimeout);
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-black">
      <div
        className="relative w-full aspect-video"
        onMouseMove={showButton}
        onMouseEnter={showButton}
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
        )}

        <ReactPlayer
          ref={playerRef}
          url={videoData?.video_url}
          width="100%"
          height="100%"
          playing={isPlaying}
          muted={isMuted}
          controls={false}
          loop={true}
          playsinline
          style={{ position: "absolute", top: 0, left: 0 }}
          className="!absolute !top-0 !left-0 !w-full !h-full opacity-70"
          config={{
            file: {
              attributes: {
                style: {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              },
            },
          }}
          onReady={() => setIsLoading(false)}
        />

        <div
          className={`absolute bottom-0 left-0 right-0 flex items-center justify-between p-4 transition-all duration-300  ${
            isButtonVisible ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="flex items-center gap-2">
            <button
              onClick={() => setIsPlaying(!isPlaying)}
              className="bg-white/90 hover:bg-white text-gray-800 p-1.5 rounded transition-colors duration-300"
            >
              {!isPlaying ? (
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                  />
                </svg>
              ) : (
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 9v6m4-6v6"
                  />
                </svg>
              )}
            </button>

            <button
              onClick={() => {
                setIsMuted(!isMuted);
                // Push custom event to dataLayer
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "Hero Video Mute",
                  heroVideoMuted: !isMuted, // Will be true when unmuting, false when muting
                });
              }}
              className="bg-white/90 hover:bg-white text-gray-800 p-1.5 rounded transition-colors duration-300"
            >
              {isMuted ? (
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"
                  />
                </svg>
              ) : (
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.536 8.464a5 5 0 010 7.072M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                  />
                </svg>
              )}
            </button>

            {videoData?.video_title && (
              <div className="relative group">
                <button
                  onClick={handleInfoClick}
                  className="bg-white/90 hover:bg-white text-gray-800 p-1.5 rounded transition-colors duration-300"
                >
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>

                <div
                  className={`absolute left-0 bottom-full mb-2 lg:mb-0 lg:bottom-auto lg:top-1/2 lg:left-full ml-2 lg:-translate-y-1/2 whitespace-normal lg:whitespace-nowrap max-w-[200px] lg:max-w-none transition-all duration-300 origin-left ${
                    showInitialTitle ||
                    showTitle ||
                    (!showInitialTitle &&
                      isButtonVisible &&
                      document.documentElement.clientWidth >= 1024)
                      ? "scale-x-100 opacity-100"
                      : "scale-x-0 opacity-0"
                  }`}
                >
                  <div className="bg-black/75 text-white px-3 py-1.5 rounded">
                    <h2 className="text-sm lg:text-base font-medium">
                      {videoData?.video_title}
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </div>

          <a
            onClick={() => {
              // Push custom event to dataLayer
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "Hero Video Watch More",
                heroVideoWatchMoreUrl: videoData?.watch_more_link,
              });
            }}
            href={videoData?.watch_more_link}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black/75 hover:bg-black text-white text-sm sm:text-base px-3 py-1.5 rounded transition-colors duration-300 flex items-center gap-2"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
            <span className="hidden sm:inline">
              {videoData?.watch_more_text}
            </span>
          </a>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 backdrop-blur-sm bg-black/50">
          <div className="relative bg-white/20 backdrop-blur-md rounded-lg p-6 max-w-sm w-full border border-white/20 shadow-xl">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-white/80 hover:text-white transition-colors"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-xl font-normal text-white mb-2">Now Playing</h2>
            <p className="text-white/90">{videoData?.video_title}</p>
          </div>
        </div>
      )}
    </div>
  );
}
