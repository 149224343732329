import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { AGENT } from "../../api/constants";
import { AiOutlinePhone } from "react-icons/ai";
import { MdOutlineMailOutline } from "react-icons/md";

import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import ReactPlayer from "react-player/lazy";
import { WebsiteContext } from "../../context/websiteContext";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

export default function AgentDetailNew() {
  const { agentName } = useParams();
  const { setShowAreas } = useContext(WebsiteContext);

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const [agent, setAgent] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    setShowAreas(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(AGENT + `${agentName}`);
        setAgent(response?.data?.agent);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [agentName]);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get(BASE_URL);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     fetchData();
  //   }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />

        <script type="application/ld+json">
          {JSON.stringify(agent?.schema_org?.properties)}
        </script>
      </Helmet>
      <div className=" bg-white ">
        {/* Hero Section */}
        <div className="mt-16">
          <div className="container mx-auto px-4 ">
            <div className="grid md:grid-cols-2 gap-12 items-start max-w-6xl 2xl:mx-auto ">
              {/* Image Column */}
              <div className="w-full h-full md:h-[600px] xl:h-[700px] 2xl:h-[700px] ">
                <img
                  src={agent?.profile_picture}
                  alt={agent?.name}
                  className="w-full h-full object-cover lg:object-fill xl:object-contain 2xl:object-cover rounded-lg"
                />
              </div>

              {/* Info Column */}
              <div className="space-y-8">
                <div>
                  <h1 className="text-4xl font-normal mb-2 text-gray-900">
                    {agent?.name}
                  </h1>
                  <p className="text-xl text-gray-600">
                    {agent?.specialties?.[0]}
                  </p>
                </div>

                {/* Experience Info */}
                <div className="space-y-4 border-t border-gray-300 pt-6">
                  <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                    <div>
                      <p className="text-gray-600">Experience:</p>
                      <p className="text-gray-900">{agent?.experience} years</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Specialization:</p>
                      <p className="text-gray-900">{agent?.specialties?.[0]}</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Language:</p>
                      <p className="text-gray-900">
                        {agent?.languages?.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Contact Buttons */}
                <div className="grid grid-cols-3 gap-4 pt-6">
                  <a
                    href={`tel:${agent?.phone}`}
                    className="flex items-center justify-center gap-2 border border-gray-300 rounded-lg py-3 px-4 text-black hover:text-white hover:bg-black transition"
                  >
                    <AiOutlinePhone className="w-5 h-5" />
                  </a>
                  <a
                    href={`mailto:${agent?.email}`}
                    className="flex items-center justify-center gap-2 border border-gray-300 rounded-lg py-3 px-4 text-blue-400 hover:text-white hover:bg-blue-600 transition"
                  >
                    <MdOutlineMailOutline className="w-5 h-5" />
                  </a>
                  <a
                    href={`https://wa.me/${agent?.phone}?text=Hello ${agent?.name}, saw your profile on Xperience Realty Website, can we connect?`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center gap-2 border border-gray-300 rounded-lg py-3 px-4 text-green-600  hover:bg-green-700 hover:text-white transition"
                  >
                    <FaWhatsapp className="w-5 h-5" />
                  </a>
                </div>

                {/* About Section */}
                {!isTablet && (
                  <div className="prose max-w-none">
                    <h2 className="text-2xl font-normal mb-6">About</h2>
                    <p className="text-gray-600 text-justify leading-relaxed">
                      {agent?.bio}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* About Section Tablet Only */}
        {isTablet && (
          <div className="container mx-auto px-4 py-16">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-2xl font-normal mb-6">About</h2>
              <div className="prose max-w-none">
                <p className="text-gray-600 text-justify leading-relaxed">
                  {agent?.bio}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Videos Section */}
        {agent?.video_links?.length > 0 &&
        agent?.video_links[0].trim() !== "" ? (
          <div className="container mx-auto px-4 py-16">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-3xl font-normal mb-8">Featured Videos</h2>
              <div className="grid sm:grid-cols-2 gap-8">
                {agent?.video_links?.map((url, i) => (
                  <div
                    key={i}
                    className="aspect-video rounded-lg overflow-hidden shadow-lg"
                  >
                    <ReactPlayer url={url} width="100%" height="100%" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto px-4 mt-28 pb-16">
            <div className="max-w-6xl mx-auto text-center">
              <h2 className="text-2xl font-normal mb-8">
                Find Your Dream Home
              </h2>
              <p className="text-gray-600 mb-8">
                Let me help you find the perfect property that matches your
                needs and preferences.
              </p>
              <Link
                reloadDocument
                to="/contact-us-dubai-real-estate-agency/"
                className="bg-black text-white px-8 py-3 rounded-lg hover:bg-gray-700 transition"
              >
                Start Your Search
              </Link>
            </div>
          </div>
        )}

        {/* Social Links */}
        {/* <div className="container mx-auto px-4 py-16 border-t">
          <div className="max-w-6xl mx-auto">
            <div className="flex justify-center gap-6">
              {agent?.social_links?.youtube && (
                <a
                  href={agent.social_links.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-600 transition"
                >
                  <FaYoutube className="w-8 h-8 text-red-600" />
                </a>
              )}
              {agent?.social_links?.facebook && (
                <a
                  href={agent.social_links.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-600 transition"
                >
                  <FaFacebook className="w-8 h-8 text-blue-600" />
                </a>
              )}
              {agent?.social_links?.linkedin && (
                <a
                  href={agent.social_links.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-600 transition"
                >
                  <FaLinkedin className="w-8 h-8 text-blue-600" />
                </a>
              )}
              {agent?.social_links?.instagram && (
                <a
                  href={agent.social_links.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-600 transition"
                >
                  <FaInstagram className="w-8 h-8 text-pink-600" />
                </a>
              )}
              {agent?.social_links?.twitter && (
                <a
                  href={agent.social_links.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-600 transition"
                >
                  <FaTwitter className="w-8 h-8 text-blue-500" />
                </a>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
