export const BASE_URL = "https://api.xrealty.ae/";
// export const BASE_URL = "http://localhost:3333/";
export const STRAPI_BASE_URL = "https://admin-v1.xrealty.ae";
export const FETCH_DEVELOPER = `${BASE_URL}label/`;
export const XR_TEAM = `${BASE_URL}meet-the-xr`;
export const XPLORE_DUBAI = `${BASE_URL}area/`;
export const AGENT = `${BASE_URL}agent/`;
export const STAR_AGENTS = `${BASE_URL}starAgents`;
export const NEWS_AND_INSIGHTS = `${BASE_URL}real-estate-news`;
export const PROPERTY = `${BASE_URL}property`;
export const CONTACT_US = `${BASE_URL}contact`;
export const CONTACT_US_CAREER = `${BASE_URL}career_contact`;
export const REVIEWS = `${BASE_URL}reviews`;
export const ALL_REVIEWS = `${BASE_URL}admin/getAllReviews`;
export const WEB_URL = "https://www.xrealty.ae/";
export const ALL_COMMUNITIES = `${BASE_URL}area/communities/`;
export const FETCH_All_DEVELOPERS = `${BASE_URL}admin/developers`;
export const FETCH_BLOGS = `${BASE_URL}blogs`;
export const FETCH_PROPERTY_TYPES = `${BASE_URL}propertyType`;
export const RESUME_UPLOAD = `${BASE_URL}resume/upload`;
export const FETCH_ABOUT = `${BASE_URL}about-us`;
export const FETCH_GUIDE = `${STRAPI_BASE_URL}/api/guides`;
export const FETCH_GUIDE_DETAILS = `${STRAPI_BASE_URL}/api/guides/`;
export const FETCH_AREAS_STRAPI = `${STRAPI_BASE_URL}/api/area`;
export const FETCH_CARDS_DATA_AREA_PAGE = `${BASE_URL}area/communities`;
export const FETCH_FAQ = `${STRAPI_BASE_URL}/api/faq-page`;
export const FETCH_TYPES_MAP = `${STRAPI_BASE_URL}/api/types-maps`;
export const GET_CONTENT_DUBAI_PROPERTIES_BY_TYPE = `${STRAPI_BASE_URL}/api/dubai-properties-contents`;
export const GET_CONTENT_LIVING_EXPERIENCE_DUBAI = `${STRAPI_BASE_URL}/api/living-experience`;
export const GET_CONTENT_LIVING_EXPERIENCE_DUBAI_TYPE = `${STRAPI_BASE_URL}/api/living-experience-content-types`;
export const GET_HOME_PAGE_DATA = `${STRAPI_BASE_URL}/api/home`;
export const NEWSLETTER_SUBSCRIBERS = `${STRAPI_BASE_URL}/api/newsletter-subscribers`;
export const ALL_DEVELOPERS_PAGE = `${STRAPI_BASE_URL}/api/all-developer`;
export const FETCH_ALL_DEVELOPERS_STRAPI = `${STRAPI_BASE_URL}/api/developers`;
export const FETCH_ALL_AGENTS_PAGE_STRAPI = `${STRAPI_BASE_URL}/api/all-agent`;
export const PRIVACY_POLICY_NEW = `${STRAPI_BASE_URL}/api/privacy-policy`;
