import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player/lazy";

export default function ProjectOfTheMonth({
  videoUrl,
  watchMoreText,
  watchMoreUrl,
}) {
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const playerRef = useRef(null);
  let hideTimeout;

  const showButton = () => {
    setIsButtonVisible(true);
    clearTimeout(hideTimeout);
    hideTimeout = setTimeout(() => {
      setIsButtonVisible(false);
    }, 3000);
  };

  useEffect(() => {
    showButton();
    setIsPlaying(true);
    return () => clearTimeout(hideTimeout);
  }, []);

  return (
    <div className="relative aspect-video container mx-auto rounded-lg overflow-hidden bg-black">
      <div
        className="aspect-video relative"
        onMouseMove={showButton}
        onMouseEnter={showButton}
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <ReactPlayer
          className="opacity-75"
          ref={playerRef}
          url={videoUrl}
          width="100%"
          height="100%"
          playing={isPlaying}
          muted={isMuted}
          controls={false}
          loop={true}
          onReady={() => setIsLoading(false)}
        />

        <div
          className={`absolute bottom-4 left-4 flex items-center gap-3 transition-all duration-300 ${
            isButtonVisible ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="bg-white/90 hover:bg-white text-gray-800 p-2 rounded-md transition-colors duration-300"
          >
            {!isPlaying ? (
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 9v6m4-6v6"
                />
              </svg>
            )}
          </button>

          <button
            onClick={() => {
              setIsMuted(!isMuted);
              // Push custom event to dataLayer
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "POM Video Mute",
                pomVideoMuted: !isMuted, // Will be true when unmuting, false when muting
              });
            }}
            className="bg-white/90 hover:bg-white text-gray-800 p-2 rounded-md transition-colors duration-300"
          >
            {isMuted ? (
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15.536 8.464a5 5 0 010 7.072M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                />
              </svg>
            )}
          </button>
        </div>

        <a
          onClick={() => {
            // Push custom event to dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "POM Video Watch More",
              pomVideoWatchMoreUrl: watchMoreUrl,
            });
          }}
          href={watchMoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={`absolute bottom-4 right-4 flex items-center gap-2 bg-gray-900/90 hover:bg-gray-900 text-white px-3 py-2 rounded-md transition-all duration-300 ${
            isButtonVisible ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <span className="hidden sm:inline text-sm font-medium">
            {watchMoreText}
          </span>
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
