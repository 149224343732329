import React, { useEffect, useState, useContext } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import { BASE_URL, GET_HOME_PAGE_DATA, FETCH_GUIDE } from "../api/constants";
import qs from "qs";
import GoogleReviews from "../components/GoogleReviews/GoogleReviews";
import CommunitySection from "../components/Community/CommunitySection";
import Slider from "react-slick";
import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperties";
import ProjectOfTheMonth from "../components/ProjectOfTheMonth/ProjectOfTheMonth";
import FAQs from "../components/FAQs/FAQs";
import HeroVideo from "../components/HeroVideo/HeroVideo";
import { useMediaQuery } from "react-responsive";
import GuidesCardV2 from "../components/GuidesCard/GuidesCardV2";
import NewsAndInsightsCardV3 from "../components/NewsAndInsightsCard/NewsAndInsightsCardV3";
import AgentCardV2 from "../components/AgentCard/AgentCardV2";
import GoogleReviewsMobile from "../components/GoogleReviews/GoogleReviewsMobile";
import RegisterInterestNew from "../components/RegisterInterestNew/RegisterInterestNew";
import {
  GoogleReviewsDesktopShimmer,
  GoogleReviewsMobileShimmer,
} from "../components/GoogleReviews/GoogleReviewsShimmer";
import CommunityShimmer from "../components/Community/CommunityShimmer";
import AgentCardShimmer from "../components/AgentCard/AgentCardShimmer";
import FeaturedPropertiesShimmer from "../components/FeaturedProperties/FeaturedPropertiesShimmer";
import FindYourDreamHome from "../components/FIndYourDreamHome/FindYourDreamHome";
import { WebsiteContext } from "../context/websiteContext";
import HeroVideo2 from "../components/HeroVideo/HeroVideo2";
import GoogleReviewsMobileV1 from "../components/GoogleReviews/GoogleReviewsMobileV1";

function Home() {
  const [data, setData] = useState({});
  const [newData, setNewData] = useState({});
  const [guides, setGuides] = useState([]);

  const [reviews, setReviews] = useState([]);

  const { setShowAreas } = useContext(WebsiteContext);
  useEffect(() => {
    setShowAreas(false);
  }, [setShowAreas]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const response = await axios.get(BASE_URL);
        const homePageData = response.data;
        setData(homePageData);

        setReviews(homePageData?.reviews || []);
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    };
    const queryStr = qs.stringify({
      populate: [
        "homepage_video",
        "community_section_cards",
        "community_section_cards.area_image",
        "Faqs",
        "project_of_the_month_key_features",
        "google_review_cards",
      ],
    });

    const fetchHomeDatafromStrapi = async () => {
      try {
        const response = await axios.get(`${GET_HOME_PAGE_DATA}?${queryStr}`);
        setNewData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    };

    const queryStr2 = qs.stringify({
      filters: {
        show_guide: true,
      },
      fields: ["guide_name", "hero_heading", "guide_slug"],
      populate: ["guide_featured_image"],
    });

    const fetchGuidesData = async () => {
      try {
        const response = await axios.get(`${FETCH_GUIDE}?${queryStr2}`);
        setGuides(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching guides data:", error);
      }
    };

    fetchHomeData();
    fetchHomeDatafromStrapi();
    fetchGuidesData();
  }, []);

  const CustomArrow = ({ direction, onClick }) => (
    <button
      onClick={onClick}
      className={`absolute ${
        direction === "prev"
          ? "lg:-left-12 md:-left-10 -left-8"
          : "lg:-right-12 md:-right-10 -right-8"
      } top-1/2 -translate-y-1/2 z-10 bg-white shadow-lg rounded-full p-2 hover:bg-gray-100 transition-all`}
      style={{ transform: "translateY(-50%)" }}
    >
      {direction === "prev" ? (
        <IoIosArrowBack size={24} />
      ) : (
        <IoIosArrowForward size={24} />
      )}
    </button>
  );

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    className: "agent-slider",
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          centerMode: false,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div className="overflow-x-hidden">
      <Helmet>
        <link rel="alternate" hreflang="en-ae" href="https://www.xrealty.ae/" />
        <link rel="alternate" hreflang="en-us" href="https://www.xrealty.ae/" />
        <link rel="alternate" hreflang="en-gb" href="https://www.xrealty.ae/" />
        <link rel="alternate" hreflang="en-sg" href="https://www.xrealty.ae/" />
        <link
          rel="alternate"
          hreflang="en-in"
          href=" https://www.xrealty.ae/"
        />
        <link rel="alternate" hreflang="en" href=" https://www.xrealty.ae/" />
        <link
          rel="alternate"
          hreflang="x-default"
          href=" https://www.xrealty.ae/ "
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Xperience Realty",
            url: "https://www.xrealty.ae/",
            logo: "https://res.cloudinary.com/dkhns25jh/image/upload/v1733821293/xr_media/sqmxma6remiuh1mbv4x7.png",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+97147049400",
                contactType: "sales",
              },
              {
                "@type": "ContactPoint",
                telephone: "+971564080888",
                contactType: "sales",
              },
            ],
            sameAs: [
              "https://www.facebook.com/profile.php?id=61562378023612",
              "https://x.com/xrealty_ae",
              "https://www.instagram.com/xperience.realty",
              "https://www.youtube.com/@xrealtyae",
              "https://www.linkedin.com/company/xrealty-ae",
            ],
          })}
        </script>

        <script type="text/javascript">
          {`
window.criteo_q = window.criteo_q || [];
var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
window.criteo_q.push(
 { event: "setAccount", account: "119119" }, 
  { event: "setEmail", email: "" },
 { event: "setSiteType", type: deviceType },
 { event: "viewHome" }
);
          `}
        </script>
      </Helmet>
      <style>
        {`
          .agent-slider {
            width: 100% !important;
          }
          .agent-slider .slick-track {
            display: flex;
            justify-content: center;
          }
          .agent-slider .slick-slide {
            float: none;
          }
          @media (min-width: 1024px) {
            .agent-slider .slick-slide > div {
              margin: 0 4px;
            }
          }
          @media (max-width: 1023px) {
            .agent-slider .slick-slide > div {
              margin: 0 4px;
            }
          }
        `}
      </style>

      {/* Hero Section - White Background (default) */}
      <div className="relative">
        <HeroVideo2 videoData={newData?.homepage_video} />
      </div>

      {/* Reviews Section - White Background */}
      <div className="mt-4">
        <div
          className={`md:px-6 md:mx-auto xl:w-[1200px] lg:mx-auto ${
            !isMobile ? "pt-16 px-4" : "px-1"
          }`}
        >
          {isMobile ? (
            newData?.google_review_cards?.length > 0 ? (
              <GoogleReviewsMobile
                data={{
                  mobile_google_review_overall_rating_text:
                    newData?.mobile_google_review_overall_rating_text,
                  google_overall_rating: newData?.google_overall_rating,
                  view_all_reviews_on_google_link:
                    newData?.view_all_reviews_on_google_link,
                  google_review_cards: newData?.google_review_cards,
                }}
              />
            ) : (
              <GoogleReviewsMobileShimmer />
            )
          ) : newData?.google_review_cards?.length > 0 ? (
            <GoogleReviews
              reviews={reviews}
              data={{
                google_overall_rating: newData?.google_overall_rating,
                view_all_reviews_on_google_link:
                  newData?.view_all_reviews_on_google_link,
                google_review_cards: newData?.google_review_cards,
              }}
            />
          ) : (
            <GoogleReviewsDesktopShimmer />
          )}
        </div>
      </div>

      {/* Community Section - Light Gray Background */}
      <div className="mt-2 md:mt-16">
        <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          {newData?.community_section_cards?.length > 0 ? (
            <>
              <CommunitySection
                heading={newData?.community_section_heading}
                subheading={newData?.community_section_subheading}
                communities={newData?.community_section_cards}
              />
              <Link
                reloadDocument
                to={newData?.community_section_button_url}
                className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
              >
                <span className="font-medium">
                  {newData?.community_section_button_text}
                </span>
                <svg
                  className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </>
          ) : (
            <CommunityShimmer />
          )}
        </div>
      </div>

      {/* Guides Section - Light Gray Background */}
      <div className="mt-16">
        <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          {guides?.length > 0 ? (
            <div className="flex flex-col gap-2 justify-center">
              <h2 className="text-xl font-semibold">Property Guides</h2>
              <span className="text-gray-500 text-sm">
                Comprehensive guides to help you navigate the Dubai real estate
                market with confidence
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="h-7 w-40 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-5 w-[450px] max-w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
          )}
          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 ${
              isTablet ? "lg:grid-cols-2" : "lg:grid-cols-3"
            } gap-6`}
          >
            {isMobile ? (
              <>
                {guides?.slice(0, 3)?.map((guide, index) => (
                  <GuidesCardV2
                    isLoading={!guides?.length > 0}
                    guide={guide}
                    key={index}
                  />
                ))}
              </>
            ) : isTablet ? (
              <>
                {guides?.slice(0, 4)?.map((guide, index) => (
                  <GuidesCardV2
                    isLoading={!guides?.length > 0}
                    guide={guide}
                    key={index}
                  />
                ))}
              </>
            ) : (
              <>
                {guides?.slice(0, 6)?.map((guide, index) => (
                  <GuidesCardV2
                    isLoading={!guides?.length > 0}
                    guide={guide}
                    key={index}
                  />
                ))}
              </>
            )}
          </div>
          {guides?.length > 0 ? (
            <Link
              reloadDocument
              to={"/guides/"}
              className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
            >
              <span className="font-medium">View All Guides</span>
              <svg
                className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          ) : (
            <div className="mt-8 w-36 h-10 bg-gray-200 rounded animate-pulse"></div>
          )}
        </div>
      </div>

      {/* Project of the Month Section - White Background */}
      <div className="mt-16">
        <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          {newData?.project_of_the_month_key_features ? (
            <div className="flex flex-col gap-2 justify-center mb-8">
              <h2 className="text-xl font-semibold text-center">
                {newData?.project_of_the_month_title} -{" "}
                {newData?.project_of_the_month_name}
              </h2>
              <span className="text-gray-500 text-sm text-center">
                {newData?.project_of_the_month_subtitle}
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-2 justify-center mb-8">
              <div className="h-7 w-48 bg-gray-200 rounded animate-pulse mx-auto"></div>
              <div className="h-5 w-64 bg-gray-200 rounded animate-pulse mx-auto"></div>
            </div>
          )}

          <div className="grid grid-cols-1">
            {/* Video Section - Right Side */}
            {newData?.project_of_the_month_video_url ? (
              <div className="h-full">
                <ProjectOfTheMonth
                  videoUrl={newData?.project_of_the_month_video_url}
                  watchMoreText={
                    newData?.project_of_the_month_video_watch_more_text
                  }
                  watchMoreUrl={
                    newData?.project_of_the_month_video_watch_more_url
                  }
                />
                <Link
                  reloadDocument
                  to={`${newData?.view_project_details_link}`}
                  className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
                >
                  <span className="font-medium">View Project Details</span>
                  <svg
                    className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </Link>
              </div>
            ) : (
              <div className="h-[400px] bg-gray-200 rounded animate-pulse"></div>
            )}
          </div>
        </div>
      </div>

      {/* Agent Section - White Background */}
      <div className="bg-white mt-16">
        <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          {data?.agent?.length > 0 ? (
            <div>
              <h2 className="text-xl font-semibold">Featured Agents</h2>
              <span className="text-gray-500 text-sm">
                Meet Our top Performing Real Estate Agents
              </span>
            </div>
          ) : (
            <div>
              <div className="h-7 w-40 bg-gray-200 rounded animate-pulse mb-2"></div>
              <div className="h-5 w-72 bg-gray-200 rounded animate-pulse"></div>
            </div>
          )}
          <div className="mt-4 relative w-full justify-center">
            {data?.agent?.length > 0 ? (
              <Slider {...settings}>
                {data.agent.map((person, index) => (
                  <div key={index}>
                    <AgentCardV2 person={person} />
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...settings}>
                <AgentCardShimmer />
                <AgentCardShimmer />
                <AgentCardShimmer />
                <AgentCardShimmer />
              </Slider>
            )}
          </div>
          {data?.agent?.length > 0 ? (
            <Link
              reloadDocument
              to={"/agent/"}
              className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
            >
              <span className="font-medium">View All Team</span>
              <svg
                className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          ) : (
            <div className="mt-8 w-36 h-10 bg-gray-200 rounded animate-pulse"></div>
          )}
        </div>
      </div>

      {/* Featured Properties Section - Light Gray Background */}
      <div className="mt-16">
        <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          {data?.properties?.length > 0 ? (
            <div className="flex flex-col gap-2 justify-center">
              <h2 className="text-xl font-semibold">Featured Properties</h2>
              <span className="text-gray-500 text-sm">
                Explore our handpicked selection of premium properties
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="h-7 w-48 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-5 w-[450px] max-w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
          )}

          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 gap-6 md:px-0 max-w-[1200px] mx-auto ${
              isTablet ? "md:grid-cols-2" : "md:grid-cols-3"
            }`}
          >
            {data?.properties?.length > 0 ? (
              isMobile ? (
                <>
                  {data.properties.slice(0, 3).map((property, index) => (
                    <FeaturedProperties key={index} property={property} />
                  ))}
                </>
              ) : isTablet ? (
                <>
                  {data.properties.slice(0, 4).map((property, index) => (
                    <FeaturedProperties key={index} property={property} />
                  ))}
                </>
              ) : (
                isDesktop && (
                  <>
                    {data.properties.slice(0, 6).map((property, index) => (
                      <FeaturedProperties key={index} property={property} />
                    ))}
                  </>
                )
              )
            ) : (
              <>
                {[...Array(isMobile || isTablet ? 4 : 6)].map((_, index) => (
                  <FeaturedPropertiesShimmer key={index} />
                ))}
              </>
            )}
          </div>

          {data?.properties?.length > 0 ? (
            <Link
              reloadDocument
              to={"/dubai-properties/"}
              className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
            >
              <span className="font-medium">View All Properties</span>
              <svg
                className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          ) : (
            <div className="mt-8 w-36 h-10 bg-gray-200 rounded animate-pulse"></div>
          )}
        </div>
      </div>

      {/* News and Blogs Section - Light Gray Background */}
      <div className="bg-white pt-16">
        <div className="px-4 md:px-6 md:mx-auto xl:w-[1200px] lg:mx-auto">
          {data?.content?.length > 0 ? (
            <div className="flex flex-col gap-2 justify-center">
              <h2 className="text-xl font-semibold">News and Insights</h2>
              <span className="text-gray-500 text-sm">
                Stay informed with our latest news and insights
              </span>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="h-7 w-44 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-5 w-72 bg-gray-200 rounded animate-pulse"></div>
            </div>
          )}
          <div
            className={`mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 ${
              isTablet ? "lg:grid-cols-2" : "lg:grid-cols-3"
            } gap-6`}
          >
            {isMobile ? (
              <>
                {data?.content?.slice(0, 3).map((blog, index) => {
                  return (
                    <NewsAndInsightsCardV3
                      isLoading={!data?.content?.length > 0}
                      key={index}
                      blog={blog}
                    />
                  );
                })}
              </>
            ) : isTablet ? (
              <>
                {data?.content?.slice(0, 4).map((blog, index) => {
                  return (
                    <NewsAndInsightsCardV3
                      isLoading={!data?.content?.length > 0}
                      key={index}
                      blog={blog}
                    />
                  );
                })}
              </>
            ) : (
              isDesktop && (
                <>
                  {data?.content?.slice(0, 6).map((blog, index) => {
                    return (
                      <NewsAndInsightsCardV3
                        isLoading={!data?.content?.length > 0}
                        key={index}
                        blog={blog}
                      />
                    );
                  })}
                </>
              )
            )}
          </div>
          {data?.content?.length > 0 ? (
            <Link
              reloadDocument
              to={"/real-estate-news/"}
              className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
            >
              <span className="font-medium">View All News & Insights</span>
              <svg
                className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          ) : (
            <div className="mt-8 w-44 h-10 bg-gray-200 rounded animate-pulse"></div>
          )}
        </div>
      </div>

      {/* Register Interest Section - White Background */}
      <div className="bg-white pt-16">
        <div className="px-4  md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          <RegisterInterestNew />
        </div>
      </div>

      {/* FAQs Section - White Background */}
      <div className="mt-16 mb-4">
        <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          <div className="flex flex-col gap-2 justify-center mb-8">
            <h2 className="text-xl font-semibold">
              Frequently Asked Questions
            </h2>
            <span className="text-gray-500 text-sm">
              Find answers to common questions about real estate in Dubai
            </span>
          </div>
          <FAQs faqs={newData?.Faqs} />
          <div className="flex justify-center">
            <Link
              reloadDocument
              to={"/faqs/"}
              className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
            >
              <span className="font-medium">View All Faqs</span>
              <svg
                className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Find your dream home */}
      <div className="mt-16 mb-16">
        <div className="w-full px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
          <FindYourDreamHome />
        </div>
      </div>
    </div>
  );
}

export default Home;
