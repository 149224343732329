import { createContext, useEffect, useState } from "react";
import { FETCH_TYPES_MAP } from "../api/constants";
import axios from "axios";

export const WebsiteContext = createContext();

export const WebsiteProvider = ({ children }) => {
  const [showAreas, setShowAreas] = useState(false);

  const [typesMap, setTypesMap] = useState([]);

  useEffect(() => {
    const fetchTypesMap = async () => {
      const response = await axios.get(`${FETCH_TYPES_MAP}`);
      setTypesMap(response.data.data);
    };
    fetchTypesMap();
  }, []);

  return (
    <WebsiteContext.Provider
      value={{ showAreas, setShowAreas, typesMap, setTypesMap }}
    >
      {children}
    </WebsiteContext.Provider>
  );
};
