import React, { useContext, useState } from "react";
import FindYourDreamHome from "../components/FIndYourDreamHome/FindYourDreamHome";
import { Link, useLocation } from "react-router-dom";
import { WebsiteContext } from "../context/websiteContext";
import { useEffect } from "react";
import axios from "axios";
import { FETCH_GUIDE } from "../api/constants";
import qs from "qs";
import { Helmet } from "react-helmet";

const AllGuides = () => {
  const { setShowAreas } = useContext(WebsiteContext);
  const [guides, setGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const pathname = useLocation().pathname;

  useEffect(() => {
    setShowAreas(false);
  }, [setShowAreas]);

  const fetchGuides = async () => {
    try {
      setIsLoading(true);
      const query = qs.stringify(
        {
          filters: {
            show_guide: true,
          },
          populate: ["guide_card_icon"],
          fields: ["guide_name", "hero_heading", "guide_slug", "documentId"],
        },
        {
          encodeValuesOnly: true,
        }
      );
      const response = await axios.get(`${FETCH_GUIDE}?${query}`);
      setGuides(response.data.data);
    } catch (error) {
      console.error("Error fetching guides:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGuides();
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div>
        {/* Hero Section */}
        <div className="relative h-[400px] md:h-[600px] lg:h-[500px] xl:h-[700px] w-full">
          <img
            src="https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/v1738942039/xr_media/ij7tquarsldbbvvttxtq.jpg"
            alt="Dubai Property Investment"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <h1 className="text-white text-2xl md:text-4xl font-normal text-center px-4">
              Dubai Property Investment Guide - Dubai Real Estate Market
            </h1>
          </div>
        </div>

        {/* Cards Section */}
        <div className="max-w-7xl mx-auto px-4 py-16  xl:w-[1200px]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            {guides?.map((guide) => (
              <Link
                reloadDocument
                key={guide?.documentId}
                to={`/guides/${guide?.guide_slug}/`}
                className="block hover:no-underline"
              >
                <div className="bg-white rounded-lg border border-gray-200 p-8 hover:shadow-lg transition-shadow duration-300 h-full">
                  <div className="w-12 h-12 mb-8 text-black">
                    <img
                      src={`${
                        guide?.guide_card_icon?.formats?.small?.url
                          ? guide?.guide_card_icon?.formats?.small?.url
                          : guide?.guide_card_icon?.url
                      }`}
                      alt={guide?.guide_card_icon?.name}
                      className="w-full h-full object-cover"
                    />

                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg> */}
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-black">
                    {guide?.guide_name}
                  </h3>
                  <p className="text-gray-600">{guide?.hero_heading}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="w-full px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6 mb-8">
          <FindYourDreamHome />
        </div>
      </div>
    </>
  );
};

export default AllGuides;
