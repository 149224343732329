import React, { useState } from "react";
import Markdown from "react-markdown";

export default function FAQs({ faqs }) {
  const [openIndex, setOpenIndex] = useState(null);
  if (!faqs) {
    faqs = [
      {
        question: "What real estate services does Xperience Realty offer?",
        answer:
          "Xperience Realty offers comprehensive real estate services including property sales, investment consulting, property management, and market analysis. Our RERA certified agents specialize in luxury properties and off-plan developments across Dubai.",
      },
      {
        question: "Which Dubai communities does Xperience Realty cover?",
        answer:
          "We cover all major Dubai communities including Dubai Marina, Palm Jumeirah, Downtown Dubai, Dubai Hills Estate, Arabian Ranches, and many more. Our expertise extends to both established neighborhoods and emerging communities.",
      },
      {
        question: "Is Xperience Realty a leading real estate agency?",
        answer:
          "Yes, Xperience Realty is recognized as one of Dubai's top real estate agencies. We're consistently ranked among Emaar's top real estate brokers and have established a strong track record in the luxury property market.",
      },
      {
        question: "Can Xperience Realty help me with real estate investments?",
        answer:
          "Absolutely! Our investment specialists provide detailed market insights, ROI analysis, and personalized investment strategies. We help both local and international investors make informed decisions in Dubai's real estate market.",
      },
      {
        question: "Do you provide property management services?",
        answer:
          "Yes, we offer comprehensive property management services including tenant sourcing, maintenance coordination, rent collection, and regular property inspections to ensure your investment is well-maintained.",
      },
      {
        question: "Do you provide insights into Dubai's real estate market?",
        answer:
          "Yes, we regularly publish market reports, property guides, and investment insights. Our team analyzes market trends, price movements, and development plans to keep our clients well-informed.",
      },
    ];
  }

  return (
    <div className="space-y-4">
      {faqs?.map((faq, index) => (
        <div key={index} className="border-b border-gray-200 last:border-0">
          <button
            className="w-full py-4 flex justify-between items-center text-left hover:text-blue-600 transition-colors duration-300"
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
          >
            <span className="font-medium text-base md:text-base">
              {faq.question}
            </span>
            <svg
              className={`w-5 h-5 transform transition-transform duration-300 ${
                openIndex === index ? "rotate-180" : ""
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>

          <div
            className={`overflow-hidden transition-all duration-300 ${
              openIndex === index ? "max-h-auto pb-4" : "max-h-0"
            }`}
          >
            <div
              className="text-gray-600 text-base md:text-base  prose prose-lg prose-blue max-w-none 
              prose-headings:text-base prose-headings:font-bold prose-headings:mb-4
              prose-p:text-base md:prose-p:text-base
              prose-a:text-blue-600 prose-a:hover:underline
              prose-strong:font-semibold
              prose-ul:space-y-4"
            >
              <Markdown>{faq.answer}</Markdown>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
