/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CONTACT_US } from "../api/constants";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import sha256 from "sha256";

function LetXpertsInput({ theme, onFormSubmit, registerInterest, property }) {
  const { pathname } = useLocation();

  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    pageUrl: "",
    ipAddress: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      const response = await axios.post(CONTACT_US, formData);

      if (response.status === 201) {
        setFormData(initialFormData);
        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
        }, 3000);

        localStorage.setItem("formSubmitted", "true");

        // Push custom event to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Website Lead form submit",
        });

        // Push custom event for Criteo
        window.criteo_q = window.criteo_q || [];
        const deviceType = /iPad/.test(navigator.userAgent)
          ? "t"
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
              navigator.userAgent
            )
          ? "m"
          : "d";
        window.criteo_q.push(
          { event: "setAccount", account: "119119" },
          {
            event: "setEmail",
            email: sha256(formData?.email),
            hash_method: "sha256",
          },
          { event: "setSiteType", type: deviceType },
          { event: "setZipcode", zipcode: "##Zip Code##" },
          {
            event: "trackTransaction",
            id: new Date().getTime(),
            item: [
              { id: "1", price: "1", quantity: "1" },
              {
                pageUrl: window.location.href,
                pageName: window.location.pathname,
              },
              //add a line for each additional line in the basket
            ],
          }
        );
        // Push custom event for Criteo End

        ReactPixel.trackCustom("Website Lead form submit", {
          // Optional parameters
          page: pathname,
        });
        if (onFormSubmit) onFormSubmit();
      }
    } catch (error) {
      const errorMessage =
        error.response?.status === 400
          ? error.response.data.error ||
            "Validation error. Please check your input."
          : "An error occurred during form submission.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col py-4 items-center w-full">
      {/* Header Section */}
      <div className="text-center mb-6">
        <h2
          className={`text-xl font-semibold mb-4 ${
            theme === "light" ? "text-black" : "text-white"
          } ${property ? "hidden" : "block"}`}
        >
          Connect with Our Xperts
        </h2>
        <p
          className={`text-sm ${
            theme === "light" ? "text-gray-600" : "text-gray-400"
          }`}
        >
          Our team of experienced agents is ready to assist you
        </p>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit} className="w-full space-y-4">
        <div>
          <input
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First Name"
            className={`w-full px-4 py-2 rounded-lg border transition-all text-lg placeholder:text-sm focus:outline-none ${
              theme === "light"
                ? "border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 bg-white text-black"
                : "border-gray-600 focus:border-gray-400 focus:ring-2 focus:ring-gray-400 bg-black text-white"
            }`}
            value={formData.firstname}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <input
            type="text"
            id="lastname"
            name="lastname"
            placeholder="Last Name"
            className={`w-full px-4 py-2 rounded-lg border transition-all text-lg placeholder:text-sm focus:outline-none ${
              theme === "light"
                ? "border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 bg-white text-black"
                : "border-gray-600 focus:border-gray-400 focus:ring-2 focus:ring-gray-400 bg-black text-white"
            }`}
            value={formData.lastname}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            className={`w-full px-4 py-2 rounded-lg border transition-all text-lg placeholder:text-sm focus:outline-none ${
              theme === "light"
                ? "border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 bg-white text-black"
                : "border-gray-600 focus:border-gray-400 focus:ring-2 focus:ring-gray-400 bg-black text-white"
            }`}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            className={`w-full px-4 py-2 rounded-lg border transition-all text-lg placeholder:text-sm focus:outline-none ${
              theme === "light"
                ? "border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 bg-white text-black"
                : "border-gray-600 focus:border-gray-400 focus:ring-2 focus:ring-gray-400 bg-black text-white"
            }`}
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="mx-auto flex w-fit items-center gap-2 bg-black text-white px-6 py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-base disabled:opacity-70 disabled:cursor-not-allowed"
        >
          <span className="font-medium relative">
            {isLoading ? (
              <span className="flex items-center gap-2">
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Submitting...
              </span>
            ) : (
              "Register Your Interest"
            )}
          </span>
          {!isLoading && (
            <svg
              className="w-5 h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          )}
        </button>

        <p
          className={`text-center text-sm ${
            theme === "light" ? "text-gray-600" : "text-gray-400"
          }`}
        >
          By clicking Submit, you agree to our{" "}
          <a
            href="https://www.xrealty.ae/privacy-policy/"
            className="text-blue-600 hover:underline"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.xrealty.ae/privacy-policy/"
            className="text-blue-600 hover:underline"
          >
            Privacy Policy
          </a>
        </p>
      </form>

      {/* Updated Success Modal */}
      {isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/40 backdrop-blur-sm animate-fadeIn z-50">
          <div
            className={`${
              theme === "light" ? "bg-white" : "bg-black"
            } p-8 rounded-2xl shadow-xl w-[90%] max-w-md text-center transform animate-slideUp`}
          >
            <div className="mb-4">
              <svg
                className="w-16 h-16 mx-auto text-green-500 animate-checkmark"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3
              className={`text-2xl font-medium mb-3 ${
                theme === "light" ? "text-gray-900" : "text-white"
              }`}
            >
              Thank you!
            </h3>
            <p
              className={`${
                theme === "light" ? "text-gray-600" : "text-gray-400"
              }`}
            >
              We've received your submission and will be in touch soon.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default LetXpertsInput;
