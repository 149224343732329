import React from "react";

export default function GuidesCardShimmer() {
  return (
    <div className="relative overflow-hidden rounded-lg w-full h-[300px] bg-gray-200 animate-pulse">
      {/* Content */}
      <div className="absolute bottom-0 left-0 p-6 w-full">
        {/* Location text shimmer */}
        <div className="h-8 w-48 bg-gray-300 rounded mb-2"></div>

        {/* Price text shimmer */}
        <div className="h-5 w-36 bg-gray-300 rounded mb-4"></div>

        {/* View Guide button shimmer */}
        <div className="h-6 w-28 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
}
