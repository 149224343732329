import React, { useState } from "react";

import axios from "axios";
import { CONTACT_US } from "../api/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import sha256 from "sha256";

function ContactUsForm({ formFields, contactDetails }) {
  const { pathname } = useLocation();

  // Create initialFormData dynamically based on formFields
  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
    pageUrl: "",
    ipAddress: "",
    // Add any additional fields from formFields
    ...Object.fromEntries(formFields.map((field) => [field.id, ""])),
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  async function getUserIP() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      formData.pageUrl = window.location.href;
      formData.ipAddress = await getUserIP();
      await axios.post(CONTACT_US, formData);

      // Show confirmation popup
      setIsSubmitted(true);

      // Hide the popup after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);

      // Reset form data after successful submission
      setFormData(initialFormData);

      // Push custom event to dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Website Lead form submit",
      });

      // Push custom event for Criteo
      window.criteo_q = window.criteo_q || [];
      const deviceType = /iPad/.test(navigator.userAgent)
        ? "t"
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? "m"
        : "d";
      window.criteo_q.push(
        { event: "setAccount", account: "119119" },
        {
          event: "setEmail",
          email: sha256(formData?.email),
          hash_method: "sha256",
        },
        { event: "setSiteType", type: deviceType },
        { event: "setZipcode", zipcode: "##Zip Code##" },
        {
          event: "trackTransaction",
          id: new Date().getTime(),
          item: [
            { id: "1", price: "1", quantity: "1" },
            {
              pageUrl: window.location.href,
              pageName: window.location.pathname,
            },
            //add a line for each additional line in the basket
          ],
        }
      );
      // Push custom event for Criteo End

      ReactPixel.trackCustom("Website Lead form submit", {
        page: pathname,
      });
    } catch (error) {
      toast.error(error.response?.data?.error + "");
      console.error(error.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-8 relative">
      {/* Success Popup */}
      {isSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <div className="text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6 text-green-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h3 className="mt-3 text-lg font-medium text-gray-900">
                Thank you for your message!
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                We'll get back to you as soon as possible.
              </p>
            </div>
          </div>
        </div>
      )}

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {formFields.map((field) => (
            <div key={field.id}>
              <input
                type={field.type}
                id={field.id}
                value={formData[field.id]}
                onChange={handleChange}
                placeholder={field.placeholder}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 transition duration-150 outline-none"
              />
            </div>
          ))}
        </div>

        <div>
          <textarea
            id="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
            rows="4"
            className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 transition duration-150 outline-none"
          ></textarea>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-800 transition duration-150 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Sending...
            </>
          ) : (
            "Send Message"
          )}
        </button>
      </form>

      <div className="mt-12">
        <h3 className="text-lg font-semibold text-gray-900 mb-6">
          Other Ways to Connect
        </h3>
        <div className="space-y-4">
          {contactDetails.map((detail) => (
            <a
              key={detail.label}
              href={detail.action}
              className="flex items-center space-x-4 p-4 rounded-lg hover:bg-gray-50 transition duration-150"
            >
              <img src={detail.icon} alt={detail.label} className="w-6 h-6" />
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {detail.label}
                </p>
                <p className="text-sm text-gray-600">{detail.value}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
