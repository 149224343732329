import React, { useEffect, useState } from "react";
import axios from "axios";
import { FETCH_DEVELOPER, WEB_URL } from "../api/constants";
import { Link, useLocation, useParams } from "react-router-dom";
import Helmet from "react-helmet";
import Slider from "react-slick";
import "../components/StarEmployeesImages.css";
import FindYourDreamHome from "../components/FIndYourDreamHome/FindYourDreamHome";

const projectPathNames = ["/label/omniyat/"];

function toTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="absolute right-4 top-1/2 -translate-y-1/2 z-20 bg-white/80 p-2 rounded-full hover:bg-white"
    >
      <svg
        className="w-6 h-6 text-black"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="absolute left-4 top-1/2 -translate-y-1/2 z-20 bg-white/80 p-2 rounded-full hover:bg-white"
    >
      <svg
        className="w-6 h-6 text-black"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );
};

function Developers() {
  const { id } = useParams();
  const [developer, setDeveloper] = useState();
  const [communities, setCommunities] = useState([]);

  const [slideShowData, setSlideShowData] = useState([]);

  const { pathname } = useLocation();

  const sliderConfig = {
    speed: 3500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    swipe: true,
    pauseOnHover: false,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${FETCH_DEVELOPER}${id}`);
        setDeveloper(response?.data?.aboutDeveloper);
        setCommunities(response?.data?.developerCommunities);
        setSlideShowData(response?.data?.slideShowData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Website",
            name:
              developer?.developer_name ||
              "Xperience Realty | Real Estate Dubai",
            description:
              developer?.description || "Developer details and properties",
            image: developer?.logo_img_url,
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>

      <style>
        {`
        /* Custom styles for Slick slider */
.slick-dots {
  bottom: 20px;
  z-index: 20;
}

.slick-dots li button:before {
  color: white;
  opacity: 0.5;
  font-size: 8px;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

/* Ensure arrows are visible */
.slick-prev,
.slick-next {
  z-index: 20;
}
        `}
      </style>

      {slideShowData?.length > 0 && (
        <Slider
          className="overflow-hidden"
          {...sliderConfig}
          infinite={slideShowData.length > 1}
        >
          {slideShowData?.map((slide, index) => {
            return (
              <div key={index} className="h-[92vh] w-full">
                <div className="relative min-h-screen flex flex-col">
                  <div
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${slide?.backgroundUrl})`,
                    }}
                  >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                  </div>

                  <div className="px-4 md:px-16 lg:px-16 container mx-auto relative z-10 flex-grow flex flex-col justify-center items-start text-center text-white  4xl:gap-4">
                    <div className="inline-block font-extralight px-4 py-1 bg-transparent border border-white text-xs  4xl:text-sm mb-4 self-start">
                      NEWLY LAUNCHED
                    </div>
                    <h1 className="text-lg text-left md:text-2xl 4xl:text-5xl mb-2 tracking-wider font-thin line-clamp-1 self-start ">
                      {slide?.property_name?.toUpperCase()} -{" "}
                      {slide?.developer_name?.toUpperCase()}
                    </h1>
                    <p className="text-sm md:text-lg 4xl:text-3xl  mb-6 tracking-wider font-light self-start line-clamp-1  text-left">
                      {slide.slogan}
                    </p>
                    <Link
                      reloadDocument
                      to={`${slide?.learnMore}/`}
                      className="inline-flex w-fit items-center gap-2 bg-white text-black px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-2 rounded-md transition-all duration-300 hover:bg-gray-100 group active:scale-95 text-sm sm:text-sm md:text-base"
                    >
                      <span className="font-medium">LEARN MORE</span>
                      <svg
                        className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      )}

      <div className=" container mx-auto lg:col-span-6 pt-16 pb-4 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] ">
        <div className="flex flex-col gap-8 ">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <div>
              <div className="flex flex-row items-center gap-4">
                <img
                  src={developer?.logo_img_url}
                  alt=""
                  className="w-[9rem] h-[5rem]"
                />
                <h1 className="text-2xl font-normal">
                  {developer?.developer_name}
                </h1>
              </div>
              <p className="font-normal text-justify text-sm sm:text-base mt-4 leading-loose lg:leading-[32px]  text-gray-700">
                {developer?.description}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto flex flex-col gap-10 lg:col-span-6 md:py-3 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] py-8 lg:py-16">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl ">
            {projectPathNames.includes(pathname) ? "Projects" : "Communities"}
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {communities?.map((community, index) => (
            <div
              key={index}
              className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300"
            >
              {/* Image container */}
              <div className="relative">
                <img
                  src={community?.images[0]?.url.replace(
                    "https://res.cloudinary.com/dkhns25jh/image/upload/",
                    "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/"
                  )}
                  alt={community?.name}
                  className="w-full h-48 object-cover"
                />
              </div>

              {/* Card content */}
              <div className="p-4">
                <h2 className="text-xl font-bold text-center mb-2">
                  {toTitleCase(community?.name)}
                </h2>
                <p className="text-sm text-gray-700 mb-4 text-center line-clamp-3">
                  {community?.description}
                </p>

                {/* Learn More button */}
                <div className="flex justify-center">
                  <Link
                    reloadDocument
                    to={`/area/${community?.slug}/`}
                    className="inline-flex w-fit mx-auto items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-2 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
                  >
                    <span className="font-medium">View Projects</span>
                    <svg
                      className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 mb-8">
          <FindYourDreamHome />
        </div>
      </div>
    </div>
  );
}

export default Developers;
