export const SOCIAL_LINKS = [
  {
    name: "Youtube",
    href: "https://www.youtube.com/@xrealtyae",
    icon: "youtube",
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/xperience.realty",
    icon: "instagram",
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/people/Xperience-Realty-Real-Estate/61562378023612",
    icon: "facebook",
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/xrealty-ae",
    icon: "linkedin",
  },
  { name: "Twitter", href: "https://x.com/xrealty_ae", icon: "twitter" },
];

export const CONTACT_INFO = {
  phone: "+971 5 640 80888",
  email: "info@xrealty.ae",
  address: "Dubai Hills Estate, Dubai, UAE",
};

export const INITIAL_MENU_ITEMS = [
  {
    name: "Properties",
    subMenu: [
      {
        name: "Apartments",
        href: "/dubai-properties/apartments-for-sale-in-dubai/",
        description: "Apartments for sale in Dubai",
        icon: "building-office-2",
      },
      {
        name: "Villas",
        href: "/dubai-properties/villas-for-sale-in-dubai/",
        description: "Villas for sale in Dubai",
        icon: "home",
      },
      {
        name: "Penthouses",
        href: "/dubai-properties/penthouse-for-sale-in-dubai/",
        description: "Penthouses for sale in Dubai",
        icon: "building",
      },
      {
        name: "Mansions",
        href: "/dubai-properties/mansions-for-sale-in-dubai/",
        description: "Mansions for sale in Dubai",
        icon: "building",
      },
      {
        name: "Off-Plan Projects",
        href: "/dubai-properties/",
        description: "Off-Plan Projects in Dubai",
        icon: "document",
      },
      {
        name: "Townhouses",
        href: "/dubai-properties/townhouses-for-sale-in-dubai/",
        description: "Townhouses for sale in Dubai",
        icon: "building",
      },
      {
        name: "Duplex Apartments",
        href: "/dubai-properties/duplex-apartments-for-sale-in-dubai/",
        description: "Duplex Apartments for sale in Dubai",
        icon: "building",
      },
      {
        name: "Twin Villas",
        href: "/dubai-properties/twin-villas-for-sale-in-dubai/",
        description: "Twin Villas for sale in Dubai",
        icon: "building",
      },
    ],
  },
  {
    name: "Developers",
    subMenu: [], // Populated dynamically
  },
  {
    name: "Guides",
    subMenu: [
      {
        name: "Buyers Guide",
        href: "/guides/a-complete-guide-to-buying-property-in-dubai/",
        description: "Buyers Guide",
      },
      {
        name: "Sellers Guide",
        href: "/guides/a-complete-guide-to-selling-property-in-dubai/",
        description: "Sellers Guide",
      },
      {
        name: "Tenants Guide",
        href: "/guides/a-complete-guide-to-Renting-property-in-dubai/",
        description: "Tenants Guide",
      },

      {
        name: "Landlords Guide",
        href: "/guides/a-complete-guide-to-landlords-in-dubai/",
        description: "Landlords Guide",
      },
      {
        name: "Off Plan Guide",
        href: "/guides/a-complete-guide-to-Off-Plan-in-dubai/",
        description: "Off Plan Guide",
      },
      {
        name: "Investment Guide",
        href: "/guides/a-complete-guide-to-Investment-in-dubai/",
        description: "Investment Guide",
      },
      // Add more areas as needed
    ],
  },
  { name: "XR Team", href: "/agent/" },
  {
    name: "Insights",
    subMenu: [
      // {
      //   name: "Market Reports",
      //   href: "/reports",
      //   description: "Latest real estate analytics",
      // },
      {
        name: "News & Updates",
        href: "/real-estate-news/",
        description: "Real Estate News",
      },
      {
        name: "Blogs",
        href: "/blogs/",
        description: "Real Estate Blogs",
      },
    ],
  },
  { name: "About", href: "/about-us/" },
  { name: "Contact", href: "/contact-us-dubai-real-estate-agency/" },
];
