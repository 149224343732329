import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { XPLORE_DUBAI } from "../../api/constants";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { WebsiteContext } from "../../context/websiteContext";

import FeaturedProperties from "../FeaturedProperties/FeaturedProperties";

function toTitleCase(str) {
  if (!str) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function OurCommunitiesNew() {
  const { title } = useParams();

  const { setShowAreas } = useContext(WebsiteContext);

  const [community, setCommunity] = useState();

  const [properties, setProperties] = useState([]);

  const { pathname } = useLocation();

  useEffect(() => {
    setShowAreas(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(XPLORE_DUBAI + `${title}`);
      setCommunity(response?.data?.community);
      setProperties(response?.data?.properties);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />

        <script type="application/ld+json">
          {JSON.stringify(community?.schema_org?.properties)}
        </script>
      </Helmet>

      <div className="space-y-12">
        {/* Hero Section */}
        {community?.images?.length > 0 && (
          <div className="h-[50vh] lg:h-[70vh] xl:h-[70vh]">
            <img
              src={community?.images[0]?.url}
              alt={community?.name}
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Info Card Section */}
        <div className="px-4 md:px-16 lg:px-12  xl:px-0 mx-auto md:mx-auto xl:w-[1200px] lg:mx-auto">
          <div className=" bg-white rounded-lg shadow-lg p-8 md:p-12 space-y-8">
            <div className="space-y-4">
              <h1 className="text-xl font-semibold">{community?.name}</h1>
              <p className="text-base text-gray-600 leading-relaxed text-justify">
                {community?.description}
              </p>
            </div>

            {/* Community Features */}
            {community?.amenities?.icons?.length > 0 && (
              <div className="space-y-6">
                <div className="space-y-2">
                  <h2 className="text-xl font-semibold">Community Features</h2>
                  <p className="text-base leading-relaxed text-justify text-gray-600">
                    {community?.amenities?.description}
                  </p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                  {community?.amenities?.icons?.map((icon, index) => (
                    <div
                      key={index + "-" + index}
                      className="flex flex-col items-center gap-3 p-4 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      {icon.icon_url && (
                        <img
                          className="w-8 h-8 md:w-10 md:h-10"
                          src={icon.icon_url}
                          alt={icon.icon_text}
                        />
                      )}
                      {icon.icon_text && (
                        <p className="text-sm text-center">{icon.icon_text}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Properties Section */}
        {properties?.length > 0 && (
          <section className="mx-auto px-4 md:px-16 lg:px-12  xl:px-0  md:mx-auto xl:w-[1200px] lg:mx-auto space-y-8">
            <h2 className="text-xl font-semibold">
              Projects In {toTitleCase(community?.name)}
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8">
              {properties?.map((property, index) => (
                <FeaturedProperties key={index} property={property} />
              ))}
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default OurCommunitiesNew;
