import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FindYourDreamHome from "../components/FIndYourDreamHome/FindYourDreamHome";
import { useContext, useEffect } from "react";
import { WebsiteContext } from "../context/websiteContext";
import {
  FETCH_AREAS_STRAPI,
  FETCH_CARDS_DATA_AREA_PAGE,
} from "../api/constants";
import axios from "axios";
import Markdown from "react-markdown";
import { Helmet } from "react-helmet";

function toTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

const AllCommunitiesNew = () => {
  const { setShowAreas } = useContext(WebsiteContext);
  const [data, setData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const pathname = useLocation().pathname;
  useEffect(() => {
    setShowAreas(false);
  }, [setShowAreas]);

  // From Headless CMS
  const fetchCommunities = async () => {
    const response = await axios.get(`${FETCH_AREAS_STRAPI}?populate=*`);
    setData(response.data.data);
  };

  // From Custom XR Backend
  const fetchCardsData = async () => {
    const response = await axios.get(`${FETCH_CARDS_DATA_AREA_PAGE}`);
    setCardsData(response.data.communities);
  };

  useEffect(() => {
    fetchCommunities();
    fetchCardsData();
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="max-w-[1440px] px-8 mx-auto mt-16 xl:px-24 mb-16 ">
        {/* Hero Section with text start */}
        <div className="bg-white rounded-lg py-8 px-5 w-[100%] mx-auto md:w-[100%]  shadow-lg hover:shadow-xl transition-shadow duration-300">
          <h1 className="text-2xl md:text-2xl lg:text-2xl xl:text-2xl leading-normal font-bold mb-6 ">
            {data?.heading}
          </h1>
          <div className="prose max-w-none text-sm md:text-base lg:text-base xl:text-base leading-loose lg:leading-[32px] text-gray-700">
            <Markdown>{data?.sub_heading}</Markdown>
          </div>
        </div>
        {/* Hero Section with text end */}

        {/* Cards Section start */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          {/* Card 1 */}
          {cardsData?.map((card, index) => {
            if (card?.properties <= 0) {
              return null;
            }
            return (
              <div
                key={index}
                className="bg-white rounded-lg overflow-hidden shadow-md  hover:shadow-lg transition-all duration-300"
              >
                {/* Red bookmark and image container */}
                <div className="relative">
                  <img
                    src={card?.community_images[0]?.url.replace(
                      "https://res.cloudinary.com/dkhns25jh/image/upload",
                      "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto"
                    )}
                    alt={card?.community_name}
                    className="w-full h-48 object-cover"
                  />
                </div>

                {/* Card content */}
                <div className="p-4">
                  <h2 className="text-xl font-bold text-center mb-2">
                    {toTitleCase(card?.community_name)}
                  </h2>
                  <p className="text-sm text-gray-700  mb-4 text-center line-clamp-3">
                    {card?.community_description}
                  </p>

                  {/* Projects button */}
                  <div className="flex justify-center">
                    <Link
                      reloadDocument
                      to={`/area/${card?.community_slug}/`}
                      className="inline-flex w-fit mx-auto items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-2 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
                    >
                      <span className="font-medium">
                        {/* {card?.properties}  */}
                        View Projects
                        {/* {card?.properties > 1 ? "Projects" : "Project"} */}
                      </span>
                      <svg
                        className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* Cards Section end */}
        <div className="mt-16">
          <FindYourDreamHome />
        </div>
      </div>
    </>
  );
};

export default AllCommunitiesNew;
