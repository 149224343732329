import React, { useState, useEffect, useContext, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { FETCH_ABOUT, STRAPI_BASE_URL, WEB_URL } from "../api/constants";
import { WebsiteContext } from "../context/websiteContext";
import Slider from "react-slick";
import AgentCardV2 from "../components/AgentCard/AgentCardV2";
import AgentCardShimmer from "../components/AgentCard/AgentCardShimmer";
import { Link } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import qs from "qs";

function AboutUsNew() {
  const { pathname } = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const [data, setData] = useState(null);
  const [aboutData, setAboutData] = useState(null);

  const { setShowAreas } = useContext(WebsiteContext);
  useEffect(() => {
    setShowAreas(true);
  }, [setShowAreas]);

  // From Headless CMS
  const fetchAbout = async () => {
    const query = qs.stringify(
      {
        populate: [
          "slider",
          "slider.slider_image",
          "founding_member_1_image",
          "founding_member_2_image",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const response = await axios.get(`${STRAPI_BASE_URL}/api/about?${query}`);
    setAboutData(response.data.data);
  };

  useEffect(() => {
    fetch(FETCH_ABOUT)
      .then((res) => res.json())
      .then((data) => setData(data?.agents));
  }, []);

  useEffect(() => {
    fetchAbout();
  }, []);

  const CustomArrow = ({ direction, onClick }) => (
    <button
      onClick={onClick}
      className={`absolute ${
        direction === "prev"
          ? "lg:-left-12 md:-left-10 -left-8"
          : "lg:-right-12 md:-right-10 -right-8"
      } top-1/2 -translate-y-1/2 z-10 bg-white shadow-lg rounded-full p-2 hover:bg-gray-100 transition-all`}
      style={{ transform: "translateY(-50%)" }}
    >
      {direction === "prev" ? (
        <IoIosArrowBack size={24} />
      ) : (
        <IoIosArrowForward size={24} />
      )}
    </button>
  );

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    className: "agent-slider",
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          centerMode: false,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  const handleSlideChange = useCallback((direction) => {
    if (aboutData?.slider?.length === 1) return;
    if (direction === "next") {
      setCurrentSlide((prev) =>
        prev === aboutData?.slider?.length - 1 ? 0 : prev + 1
      );
    } else {
      setCurrentSlide((prev) =>
        prev === 0 ? aboutData?.slider?.length - 1 : prev - 1
      );
    }
  }, []);

  // Add useEffect for auto-sliding (optional)
  useEffect(() => {
    // Only set up the timer if we have more than one slide
    if (!aboutData?.slider || aboutData.slider.length <= 1) return;

    const timer = setInterval(() => {
      handleSlideChange("next");
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, [aboutData?.slider, handleSlideChange]); // Add dependencies

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AboutPage",
            name: "About Us | Xperience Realty | Real Estate Dubai",
            description:
              "Learn more about Xperience Realty, a leading real estate agency in Dubai. Discover our mission, vision, and the team behind our success.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>

      <style>
        {`
          .agent-slider {
            width: 100% !important;
          }
          .agent-slider .slick-track {
            display: flex;
            justify-content: center;
          }
          .agent-slider .slick-slide {
            float: none;
          }
          @media (min-width: 1024px) {
            .agent-slider .slick-slide > div {
              margin: 0 4px;
            }
          }
          @media (max-width: 1023px) {
            .agent-slider .slick-slide > div {
              margin: 0 4px;
            }
          }
        `}
      </style>

      <div className="flex flex-col justify-center items-center mt-16 gap-8 mb-8 md:gap-16  md:mb-0">
        {/* <div className=" lg:h-[70dvh] w-full">
          <img
            className="h-full w-full object-cover"
            src="https://res.cloudinary.com/dkhns25jh/image/upload/v1724746380/xr_media/fupdihpsnq6zegrufssi.jpg"
            alt=""
          />
        </div> */}

        {/* Founding Members Section Starts */}

        <div className="w-[90%] md:w-[80%] lg:px-10 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] mx-auto">
          {/* <h2 className="text-center tracking-[0.2rem] text-base md:text-2xl font-bold mb-12 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            FOUNDING MEMBERS
          </h2> */}

          <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20  2xl:w-[1200px] mx-auto">
            {/* Left Member */}
            <div className="flex flex-col items-center lg:items-start gap-6">
              <div className="w-full h-[300px] sm:h-[300px] md:h-[300px] lg:h-[auto] md:w-full 2xl:w-[500px] 2xl:h-[500px] overflow-hidden ">
                <img
                  src={aboutData?.founding_member_1_image?.url}
                  alt={aboutData?.founding_member_1_image?.alternativeText}
                  className="w-full h-full sm:object-contain md:object-contain object-contain"
                />
              </div>
              <div className="text-center lg:text-left">
                <h3 className="text-xl md:text-2xl font-medium mb-2">
                  {aboutData?.founding_member_1_name}
                </h3>
                <p className="text-gray-600 text-sm md:text-base mb-4">
                  {aboutData?.founding_member_1_designation}
                </p>
                <p className="text-sm md:text-base font-light text-justify 2xl:w-[500px]">
                  {aboutData?.founding_member_1_bio}
                </p>
              </div>
            </div>

            {/* Right Member */}
            <div className="flex flex-col items-center lg:items-start gap-6">
              <div className="w-full h-[300px] sm:h-[300px]  md:h-[300px] lg:h-[auto] 2xl:w-[500px] 2xl:h-[500px] md:w-full overflow-hidden">
                <img
                  src={aboutData?.founding_member_2_image?.url}
                  alt={aboutData?.founding_member_2_image?.alternativeText}
                  className="w-full h-full sm:object-contain md:object-contain object-contain"
                />
              </div>
              <div className="text-center lg:text-left">
                <h3 className="text-xl md:text-2xl font-medium mb-2">
                  {aboutData?.founding_member_2_name}
                </h3>
                <p className="text-gray-600 text-sm md:text-base mb-4">
                  {aboutData?.founding_member_2_designation}
                </p>
                <p className="text-sm md:text-base font-light text-justify 2xl:w-[500px]">
                  {aboutData?.founding_member_2_bio}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Founding Members Section Ends */}

        {/* Equity Partners Section Starts */}
        <div className="w-[90%] md:w-[80%] lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] mx-auto ">
          <h2 className="text-center tracking-[0.2rem] text-sm md:text-base font-bold mb-5 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            EQUITY PARTNER
          </h2>

          <div className="flex flex-col items-center gap-6">
            <div className="text-center">
              <h3 className="text-sm md:text-base font-normal mb-2">
                {aboutData?.equity_partner_name}
              </h3>
              {/* <p className="text-gray-600 text-sm md:text-sm mb-0">
                Managing Partner & Head of Investment
              </p> */}
            </div>
          </div>
        </div>
        {/* Equity Partners Section Ends */}

        <div className="w-full border-t-2 border-dotted border-gray-400"></div>
        {/* Our Teams Section Starts */}
        <div className="w-[90%] md:w-[80%] lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] mx-auto">
          <h2 className="text-center tracking-[0.2rem] text-base md:text-2xl font-bold mb-12 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            OUR TEAM
          </h2>

          <div className="relative">
            {data ? (
              <Slider {...settings}>
                {data.map((agent, index) => (
                  <div key={index} className="px-2">
                    <AgentCardV2 person={agent} />
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {[1, 2, 3, 4].map((item) => (
                  <AgentCardShimmer key={item} />
                ))}
              </div>
            )}
          </div>

          <Link
            reloadDocument
            to={"/agent/"}
            className="mt-8 inline-flex w-fit items-center gap-2 bg-black text-white px-4 sm:px-4 md:px-6 py-2 sm:py-2 md:py-3 rounded-md transition-all duration-300 hover:bg-gray-800 group active:scale-95 text-sm sm:text-sm md:text-base"
          >
            <span className="font-medium">View All Team</span>
            <svg
              className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 text-[#4285F4] transform transition-transform duration-300 group-hover:translate-x-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>

        {/* Our Teams Section Ends */}
        <div className="w-full border-t-2 border-dotted border-gray-400"></div>

        <div className="flex flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[60%] 3xl:w-[45%] gap-4">
          <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            EXPERIENCE THE XPERIENCE
          </h2>
          <p className="text-justify text-sm md:text-base font-light xl:w-[900px] md:text-center">
            {aboutData?.experience_the_xperience}
          </p>
        </div>

        {/* <div className="w-full border-t-2 border-dotted border-gray-400"></div> */}

        {/* Content for small/medium screens */}
        <div className="flex flex-col lg:hidden gap-8 items-center md:flex-row md:items-start md:gap-16 md:px-16">
          <div className="flex flex-col items-center gap-4 w-[90%] md:w-[80%]">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center md:text-start ">
              ABOUT US
            </h2>
            <p className="text-sm md:text-base font-light text-justify md:text-justify">
              {aboutData?.about_us}
            </p>
          </div>

          <div className="flex flex-col items-center gap-4 w-[90%] md:w-[80%] ">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center ">
              OUR GOAL
            </h2>
            <p className="text-sm md:text-base font-light text-justify md:text-justify">
              {aboutData?.our_goal}
            </p>
          </div>
        </div>

        {/* Content for large screens and above */}
        <div className="hidden lg:grid lg:grid-cols-2  lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] gap-10 lg:gap-40 3xl:gap-64 items-start 2xl:w-[80%]">
          <div className="col-span-1 gap-4 flex flex-col">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center ">
              ABOUT US
            </h2>
            <p className="text-sm md:text-base font-light text-justify">
              {aboutData?.about_us}
            </p>
          </div>

          <div className="col-span-1  gap-4 flex flex-col">
            <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center ">
              OUR GOAL
            </h2>
            <p className="text-sm md:text-base font-light text-justify">
              {aboutData?.our_goal}
            </p>
          </div>
        </div>

        {/* Awards Slideshow Section */}
        <div className="w-full overflow-hidden">
          <h2 className="text-center tracking-[0.1rem] text-base md:text-2xl font-semibold  mb-6 md:mb-6 relative after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mx-auto after:mt-4 after:rounded-full">
            OUR AWARDS
          </h2>
          <div className="relative">
            {/* Images Container */}
            <div className="flex transition-transform duration-500 ease-in-out">
              {aboutData?.slider?.map((slide, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <img
                    className="w-full h-[auto] object-cover"
                    src={slide?.slider_image?.url}
                    alt={slide?.slider_image?.alternativeText}
                  />
                </div>
              ))}
            </div>

            {/* Navigation Buttons */}
            <button
              disabled={aboutData?.slider?.length === 1}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full"
              onClick={() => handleSlideChange("prev")}
            >
              ←
            </button>
            <button
              disabled={aboutData?.slider?.length === 1}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full"
              onClick={() => handleSlideChange("next")}
            >
              →
            </button>

            {/* Dots Indicator */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
              {aboutData?.slider?.map((slide, index) => (
                <button
                  key={index}
                  disabled={aboutData?.slider?.length === 1}
                  className={`w-2 h-2 rounded-full ${
                    currentSlide === index ? "bg-white" : "bg-white/50"
                  }`}
                  onClick={() => {
                    setCurrentSlide(index);
                  }}
                />
              ))}
            </div>
          </div>

          {/* Text Content */}
          <div className="flex transition-transform duration-500 ease-in-out w-full mt-2">
            <div className="flex flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[60%] 3xl:w-[45%] gap-4 mx-auto mt-4">
              {/* <h2 className="tracking-[0.1rem] text-base md:text-2xl font-semibold text-center">
                {slides[currentSlide].title}
              </h2> */}
              <p className="text-center text-sm md:text-base font-light xl:w-[900px] md:text-center line-clamp-3 ">
                {aboutData?.slider[currentSlide]?.slider_image_description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsNew;
