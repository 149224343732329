import React, { useContext, useEffect, useState } from "react";
import FAQs from "../components/FAQs/FAQs";
import { WebsiteContext } from "../context/websiteContext";
import axios from "axios";
import { FETCH_FAQ } from "../api/constants";
import qs from "qs";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const FaqsPage = () => {
  const { setShowAreas } = useContext(WebsiteContext);
  useEffect(() => {
    setShowAreas(true);
  }, []);

  const { pathname } = useLocation();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      const query = qs.stringify(
        {
          populate: ["hero_image", "card_icon", "Faqs"],
        },
        { encodeValuesOnly: true }
      );
      const response = await axios.get(`${FETCH_FAQ}?${query}`);
      setData(response.data.data);
    };
    fetchFaqs();
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="relative">
        {/* Background Image Section */}
        <div className="h-[50vh] lg:h-[70vh] xl:h-[70vh]">
          <img
            src={data?.hero_image?.url}
            alt={data?.hero_image?.alternativeText || "Dubai Real Estate Faqs"}
            className="w-full h-full object-cover"
          />
        </div>

        {/* White Card Overlay - positioned to overlap */}
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 bg-white px-4 py-8 md:px-8 md:py-8 md:w-[600px] rounded-lg shadow-xl max-w-2xl mx-auto w-[90%]">
          <div className="flex items-center gap-2 md:gap-4 mb-6">
            <img
              src={data?.card_icon?.url}
              alt="FAQ Icon"
              className="w-16 h-16 md:w-24 md:h-24"
            />

            <h1 className="text-2xl md:text-2xl font-bold text-black mb-4">
              {data?.card_title}
            </h1>
          </div>
          <p className="text-gray-600 text-base md:text-base">
            {data?.card_subtitle}
          </p>
        </div>
      </div>
      {/* Spacer div to push content below the overlapping card */}
      <div className="h-[100px] md:h-[150px]"></div>
      {/* FAQ Content Container */}
      <div className="max-w-7xl mx-auto px-6 pt-16 md:px-8 lg:px-20 xl:px-16">
        {/* FAQ content will go here */}
        <FAQs faqs={data?.Faqs} />
      </div>
    </>
  );
};

export default FaqsPage;
