import React, { useEffect } from "react";
import FeaturedProperties from "../FeaturedProperties/FeaturedProperties";
import FeaturedPropertiesShimmer from "../FeaturedProperties/FeaturedPropertiesShimmer";
import FindYourDreamHome from "../FIndYourDreamHome/FindYourDreamHome";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL, STRAPI_BASE_URL } from "../../api/constants";
import FAQs from "../FAQs/FAQs";
export default function AllProjectsNew() {
  const initialVisibleCount = {
    mobile: 4,
    tablet: 10,
    desktop: 20,
  };

  const [screenSize, setScreenSize] = React.useState("mobile");
  const [visibleProperties, setVisibleProperties] = React.useState(
    initialVisibleCount.mobile
  );
  const [data, setData] = React.useState([]);
  const [pageHeading, setPageHeading] = React.useState("");
  const [pageDescription, setPageDescription] = React.useState("");
  const [faqs, setFaqs] = React.useState([]);
  const { pathname } = useLocation();
  const [currenUrl, setCurrentUrl] = React.useState(useLocation().pathname);

  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setScreenSize("desktop");
        setVisibleProperties(
          Math.min(initialVisibleCount.desktop, data?.length)
        );
      } else if (width >= 768) {
        setScreenSize("tablet");
        setVisibleProperties(
          Math.min(initialVisibleCount.tablet, data?.length)
        );
      } else {
        setScreenSize("mobile");
        setVisibleProperties(
          Math.min(initialVisibleCount.mobile, data?.length)
        );
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    data?.length,
    initialVisibleCount.desktop,
    initialVisibleCount.mobile,
    initialVisibleCount.tablet,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${
            currenUrl.split("/")[1] + "/" + currenUrl.split("/")[2]
          }`
        );

        setData(response.data.properties);
        setPageHeading(response.data.pageHeading);
        setPageDescription(response.data.pageDescription);
        // setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchFaqs = async () => {
      try {
        const response = await axios.get(
          `${STRAPI_BASE_URL}/api/faq-page?populate[0]=Faqs`
        );
        setFaqs(response?.data?.data?.Faqs?.slice(0, 10));
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchData();
    fetchFaqs();
  }, [currenUrl]);

  const handleViewMore = () => {
    setVisibleProperties(data?.length);
  };

  const handleViewLess = () => {
    const currentInitialCount = initialVisibleCount[screenSize];
    setVisibleProperties(currentInitialCount);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="container mx-auto mt-12 px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
        <section className="space-y-4">
          <h1 className="text-xl font-semibold">
            {pageHeading.trim() !== ""
              ? pageHeading
              : "Off-plan Projects For Sale in Dubai"}
          </h1>
          <div
            className="text-justify text-sm leading-relaxed"
            dangerouslySetInnerHTML={{
              __html:
                pageDescription?.trim() !== ""
                  ? pageDescription
                  : "Looking for a brand new place to call home in Dubai? Xperience Realty has you covered. Explore our collection of new developments, filter by your preferences, or give us a call to discuss your dream property.",
            }}
          ></div>
        </section>

        <section className="mt-8 mb-8 grid grid-cols-1 sm:grid-cols-2 gap-6 md:px-0 max-w-[1200px] mx-auto lg:grid-cols-3">
          {data?.length > 0 ? (
            <>
              {data?.slice(0, visibleProperties).map((property, index) => (
                <FeaturedProperties key={index} property={property} />
              ))}
            </>
          ) : (
            <>
              {[...Array(initialVisibleCount[screenSize])].map((_, index) => (
                <FeaturedPropertiesShimmer key={index} />
              ))}
            </>
          )}
        </section>

        {data?.length > 0 && (
          <div className="flex justify-center mb-8">
            {visibleProperties < data?.length ? (
              <button
                onClick={handleViewMore}
                className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
              >
                View More
              </button>
            ) : (
              <button
                onClick={handleViewLess}
                className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
              >
                View Less
              </button>
            )}
          </div>
        )}
        <div className="mb-8">
          <FindYourDreamHome />
        </div>
        <div className="mb-8">
          <FAQs faqs={faqs} />
        </div>
      </div>
    </>
  );
}
