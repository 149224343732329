import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { WebsiteContext } from "../context/websiteContext";
import FAQs from "../components/FAQs/FAQs";
import RegisterInterestNew from "../components/RegisterInterestNew/RegisterInterestNew";
import NotFound from "../components/NotFound";
import qs from "qs";
import axios from "axios";
import { GET_CONTENT_DUBAI_PROPERTIES_BY_TYPE } from "../api/constants";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

const PropertiesForSale = () => {
  const { saleType, type } = useParams();

  const [propertyTypesMapName, setPropertyTypesMapName] = useState([]);
  const [propertySaleTypesMapName, setPropertySaleTypesMapName] = useState([]);

  const { setShowAreas, typesMap } = useContext(WebsiteContext);

  const [content, setContent] = useState([]);
  const { pathname } = useLocation();

  const query = qs.stringify({
    populate: {
      property_cards: {
        populate: ["card_image", "property_variants"],
      },
      faqs: true,
    },
    filters: {
      listing_type: {
        slug: {
          $eq: saleType,
        },
      },
      property_type: {
        slug: {
          $eq: type,
        },
      },
    },
  });

  const fetchContentByType = async () => {
    const response = await axios.get(
      `${GET_CONTENT_DUBAI_PROPERTIES_BY_TYPE}?${query}`
    );
    setContent(response.data.data);
  };

  useEffect(() => {
    setShowAreas(true);
    setPropertyTypesMapName(typesMap[0]?.allTypesMap[type]);
    setPropertySaleTypesMapName(typesMap[0]?.saleTypesMap[saleType]);
    fetchContentByType();
  }, [saleType, setShowAreas, type, typesMap]);

  // Update state variables
  const [rotatingCards, setRotatingCards] = useState({});
  const [currentTypeIndices, setCurrentTypeIndices] = useState({});

  // Update these constants for different screen sizes
  const initialVisibleCount = {
    mobile: 4,
    tablet: 10,
    desktop: 20,
  };

  // Update state to track screen size
  const [screenSize, setScreenSize] = React.useState("mobile");
  const [visibleProperties, setVisibleProperties] = React.useState(
    initialVisibleCount.mobile
  );

  // Add screen size detection
  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        // lg breakpoint
        setScreenSize("desktop");
        setVisibleProperties(
          Math.min(
            initialVisibleCount.desktop,
            content[0]?.property_cards?.length
          )
        );
      } else if (width >= 768) {
        // md breakpoint
        setScreenSize("tablet");
        setVisibleProperties(
          Math.min(
            initialVisibleCount.tablet,
            content[0]?.property_cards?.length
          )
        );
      } else {
        setScreenSize("mobile");
        setVisibleProperties(
          Math.min(
            initialVisibleCount.mobile,
            content[0]?.property_cards?.length
          )
        );
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    content,
    initialVisibleCount.desktop,
    initialVisibleCount.mobile,
    initialVisibleCount.tablet,
  ]);

  // Update view handlers
  const handleViewMore = () => {
    setVisibleProperties(content[0]?.property_cards?.length);
  };

  const handleViewLess = () => {
    const currentInitialCount = initialVisibleCount[screenSize];
    setVisibleProperties(currentInitialCount);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to handle type rotation
  const rotateType = React.useCallback(
    (cardIndex) => {
      const variants = content[0]?.property_cards[cardIndex]?.property_variants;
      // Only rotate if there are multiple types for this card
      if ((variants?.length || 0) > 1) {
        setRotatingCards((prev) => ({ ...prev, [cardIndex]: true }));
        setTimeout(() => {
          setCurrentTypeIndices((prev) => ({
            ...prev,
            [cardIndex]: ((prev[cardIndex] || 0) + 1) % variants.length,
          }));
          setRotatingCards((prev) => ({ ...prev, [cardIndex]: false }));
        }, 750);
      }
    },
    [content]
  );

  // Set up interval for rotation only if there are multiple types
  React.useEffect(() => {
    const intervals = content[0]?.property_cards?.map((card, index) => {
      if ((card?.property_variants?.length || 0) > 1) {
        return setInterval(() => rotateType(index), 4000);
      }
      return null;
    });

    return () =>
      intervals?.forEach((interval) => interval && clearInterval(interval));
  }, [content, rotateType]);

  // if (!propertyTypesMapName || !propertySaleTypesMapName) {
  //   return <NotFound />;
  // }

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
      </Helmet>
      <div className="container mx-auto px-4 py-8 md:mt-8 xl:w-[1200px]">
        {/* <h1 className="text-2xl font-bold mb-8">
        {propertyTypesMapName} {propertySaleTypesMapName} in Dubai
      </h1> */}
        <div
          className="prose prose-lg prose-blue max-w-none text-gray-700 
              prose-headings:text-2xl prose-headings:font-bold prose-headings:mb-8
              prose-p:text-base md:prose-p:text-lg
              prose-a:text-blue-600 prose-a:hover:underline
              prose-strong:font-semibold
              prose-ul:space-y-4"
        >
          <ReactMarkdown>{content[0]?.title}</ReactMarkdown>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {content[0]?.property_cards
            ?.slice(0, visibleProperties)
            .map((property, index) => (
              <Link
                reloadDocument
                to={property?.url}
                key={index}
                className="relative w-full h-[300px] rounded-lg overflow-hidden"
              >
                {/* Background Image */}
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${
                      property?.card_image?.formats?.medium?.url
                        ? property?.card_image?.formats?.medium?.url
                        : property?.card_image?.url
                    })`,
                  }}
                >
                  {/* Overlay for better text visibility */}
                  <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                </div>

                {/* Top Labels */}
                <div className="absolute top-4 right-4 flex gap-3">
                  <span className="px-4 py-1 bg-black text-white rounded-full text-sm font-medium">
                    {property?.property_status}
                  </span>
                  <span className="px-4 py-1 bg-white bg-opacity-90 rounded-full text-sm font-medium">
                    {property?.price}
                  </span>
                </div>

                {/* Bottom Content */}
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent">
                  <h3 className="text-white text-xl font-medium mb-2">
                    {property?.name}
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    <span
                      className={`px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm
                    transition-all duration-1000 ease-in-out
                    ${
                      (property?.property_variants?.length || 0) > 1 &&
                      rotatingCards[index]
                        ? "animate-rotate-x"
                        : ""
                    }`}
                      style={{
                        transformStyle: "preserve-3d",
                        perspective: "1000px",
                      }}
                    >
                      {
                        property?.property_variants[currentTypeIndices[index]]
                          ?.bedrooms
                      }{" "}
                      BR{" "}
                      {property?.property_variants[
                        currentTypeIndices[index]
                      ]?.name.toUpperCase()}
                    </span>
                    <span className="px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm">
                      {property?.developer_name}
                    </span>
                    <span className="px-3 py-1 bg-white bg-opacity-20 rounded-full text-white text-sm">
                      {property?.community_name}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </div>

        {/* View Controls - Responsive for all screen sizes */}
        <div className="mt-8 text-center">
          {((screenSize === "mobile" &&
            content[0]?.property_cards?.length > initialVisibleCount.mobile) ||
            (screenSize === "tablet" &&
              content[0]?.property_cards?.length >
                initialVisibleCount.tablet) ||
            (screenSize === "desktop" &&
              content[0]?.property_cards?.length >
                initialVisibleCount.desktop)) &&
          visibleProperties < content[0]?.property_cards?.length ? (
            <button
              onClick={handleViewMore}
              className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
            >
              View More Properties
            </button>
          ) : (
            visibleProperties > initialVisibleCount[screenSize] && (
              <button
                onClick={handleViewLess}
                className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
              >
                Show Less
              </button>
            )
          )}
        </div>

        {/* Property Information Section */}
        <div className="mt-16 space-y-8">
          {content.map((item) => (
            <div
              className="prose prose-lg prose-blue max-w-none text-gray-700 
              prose-headings:text-xl prose-headings:font-bold prose-headings:mb-4
              prose-p:text-base md:prose-p:text-base
              prose-p:text-justify
              prose-p:leading-relaxed
              prose-a:text-blue-600 prose-a:hover:underline
              prose-strong:font-semibold
              prose-ul:text-base
              prose-ul:space-y-2 border shadow-lg px-4 py-8 rounded-lg"
              key={item.id}
            >
              <ReactMarkdown>{item?.content}</ReactMarkdown>
            </div>
          ))}
        </div>

        {/* Register Interest Section - White Background */}
        <div className="bg-white pt-16">
          <div className="px-0 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
            <RegisterInterestNew />
          </div>
        </div>
        {/* Register Interest Section End */}

        {/* Faqs */}
        <div className="mt-12">
          <h1 className="text-2xl font-bold mb-8">FAQs</h1>
          <FAQs faqs={content[0]?.faqs} />
        </div>
      </div>
    </>
  );
};

export default PropertiesForSale;
