import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import { PROPERTY } from "../../api/constants";
import axios from "axios";
import LetXpertsInput from "../LetXpertsInput";
import { Helmet } from "react-helmet";
import "../StarEmployeesImages.css"; // Import your custom CSS
import { WebsiteContext } from "../../context/websiteContext";
import ImageSlider from "../ImageSlider/ImageSlider";

function ProjectDetailsNew() {
  const scrollToRef = useRef(null);
  const [data, setData] = useState();

  const { title } = useParams();

  const { pathname } = useLocation();

  const { setShowAreas } = useContext(WebsiteContext);

  useEffect(() => {
    setShowAreas(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(PROPERTY + `/${title}`);

        if (response.data.success) {
          setData(response?.data?.property);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [title]);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white p-2 rounded-full shadow-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white p-2 rounded-full shadow-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <style>
        {`
        .slick-dots {
  bottom: 20px;
}

.slick-dots li button:before {
  color: white;
  opacity: 0.8;
  font-size: 8px;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}
        `}
      </style>
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>{data?.property_name}</title> */}
        <script type="application/ld+json">
          {JSON.stringify(data?.schema_org?.properties)}
        </script>
        <script type="text/javascript">
          {`
window.criteo_q = window.criteo_q || [];
var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
window.criteo_q.push(
 { event: "setAccount", account: "119119" },
  { event: "setEmail", email: "" },
 { event: "setSiteType", type: deviceType },
 { event: "viewItem", item: "${window.location.href}" }
);
          `}
        </script>
      </Helmet>

      <Slider className="overflow-hidden relative" {...settings}>
        {data?.gallery1?.map((image, index) => (
          <div key={index} className="relative">
            <div className="absolute inset-0 bg-black/30 z-[1]"></div>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full aspect-video lg:h-[80dvh] object-cover"
            />
          </div>
        ))}
      </Slider>

      <div className="container mx-auto mt-5 lg:mt-20 px-4 md:px-8 flex flex-col gap-8">
        <div className="flex flex-col items-center gap-8">
          {/* Project Overview Section */}
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2">
              <h1 className="text-2xl lg:text-2xl mb-6 after:content-[''] after:block after:w-24 after:h-1 after:bg-gray-800 after:mt-4 after:rounded-full">
                {data?.property_name}
              </h1>
              <p className="text-gray-600 leading-relaxed text-justify mb-8">
                {data?.section_1?.description}
              </p>

              {/* Key Details Grid */}
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="border p-4">
                  <div className="text-gray-500 text-sm">Starting Price</div>
                  <div className="text-xl font-normal">{data?.price}</div>
                </div>
                {data?.type?.map((type, index) => (
                  <div key={index} className="border p-4">
                    <div className="text-gray-500 text-sm">Unit Type</div>
                    <div className="text-xl font-normal">
                      {type.bedrooms} BR {type.name.toUpperCase()}
                    </div>
                  </div>
                ))}
                {data?.features?.year_built && (
                  <div className="border p-4">
                    <div className="text-gray-500 text-sm">Handover Date</div>
                    <div className="text-xl font-normal">
                      {data?.features?.year_built}
                    </div>
                  </div>
                )}
                {data?.payment_plan && data?.payment_plan.trim() !== "" && (
                  <div className="border p-4">
                    <div className="text-gray-500 text-sm">Payment Plan</div>
                    <div className="text-xl font-normal">
                      {data?.payment_plan.replace("Payment Plan", "")}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Contact Form Section */}
            <div className="bg-gray-50 p-6">
              <h3 className="text-xl text-center mb-4">
                Register your interest
              </h3>
              <LetXpertsInput theme="light" property={true} />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-32 px-4 md:px-8 flex flex-col">
        {data?.images?.map((section, index) => (
          <section key={index} className="lg:mb-12 h-auto">
            <div className="mx-auto pb-8 lg:m-0 lg:p-0">
              <div
                className={`grid grid-cols-1 gap-8 lg:gap-28 lg:grid-cols-2 items-center ${
                  index % 2 === 0 ? "" : "lg:flex-row-reverse"
                }`}
              >
                <div
                  className={`relative overflow-hidden h-auto lg:col-span-1 ${
                    index % 2 === 0 ? "lg:order-first" : "lg:order-last"
                  }`}
                >
                  <img
                    alt={section.heading}
                    src={section.url}
                    className="aspect-video rounded-lg object-cover w-full "
                  />
                </div>
                <div className="lg:col-span-1 flex flex-col gap-4">
                  <span className="flex items-center">
                    <span className="pr-6 font-bold text-sm">
                      {section.heading?.toUpperCase()}
                    </span>
                    <span className="h-px w-32 bg-gray-300"></span>
                  </span>
                  <p className="text-gray-600 leading-relaxed text-justify">
                    {section.description}
                  </p>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>

      <div className="container mx-auto mt-32 px-4 md:px-8 flex flex-col">
        <div className="flex flex-col space-y-10">
          <span className="flex items-center">
            <span className="pr-6 text-gray-400 text-base md:text-2xl">
              {data?.about_project?.heading.toUpperCase()}
            </span>
            <span className="h-px w-32 bg-gray-300"></span>
          </span>
          <h2 className="text-base md:text-2xl mb-4">
            {data?.about_project?.title.toUpperCase()}
          </h2>
          <p className="text-gray-600 leading-relaxed text-justify">
            {data?.about_project?.description}
          </p>
        </div>
      </div>

      <div className="container mx-auto mt-32 px-4 md:px-8 flex flex-col gap-8">
        {/* Gallery Section */}
        <div>
          <div className="flex items-center mb-12">
            <h2 className="text-2xl font-normal text-gray-800">Gallery</h2>
            <span className="h-px flex-grow ml-6 bg-gray-300"></span>
          </div>

          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-12">
            {/* First Gallery */}
            {data?.gallery1?.length > 0 && (
              <div className="bg-white p-6">
                <h3 className="text-lg font-normal mb-6 text-center">
                  {data?.gallery_title_1}
                </h3>
                <ImageSlider images={data?.gallery1} />
                {data?.gallery_description_1?.trim() && (
                  <p className="text-gray-600 text-center mt-6 max-w-2xl mx-auto">
                    {data?.gallery_description_1}
                  </p>
                )}
              </div>
            )}

            {/* Second Gallery */}
            {data?.gallery2?.length > 0 && (
              <div className="bg-white p-6">
                <h3 className="text-lg font-normal mb-6 text-center">
                  {data?.gallery_title_2}
                </h3>
                <ImageSlider images={data?.gallery2} />
                {data?.gallery_description_2?.trim() && (
                  <p className="text-gray-600 text-center mt-6 max-w-2xl mx-auto">
                    {data?.gallery_description_2}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Amenities Section */}
        <div className="bg-white">
          <div className="flex items-center mb-12">
            <h2 className="text-2xl font-normal text-gray-800">Amenities</h2>
            <span className="h-px flex-grow ml-6 bg-gray-100"></span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12">
            {data?.amenities?.icons?.map((icon, index) => (
              <div
                key={index}
                className="group flex flex-col items-center text-center transition-all duration-300 hover:-translate-y-1"
              >
                <div className="mb-4 p-4 rounded-full bg-gray-50 group-hover:bg-gray-100 transition-colors">
                  <img
                    src={icon?.icon_url}
                    alt={icon?.icon_text}
                    className="w-8 h-8 object-contain opacity-75"
                  />
                </div>
                <span className="text-sm text-gray-600 font-normal tracking-wide">
                  {icon?.icon_text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <section className="container mx-auto mt-32 px-4 md:px-8">
        <iframe
          className="rounded-lg"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={`https://maps.google.com/maps?q=${data?.location?.coordinates?.lat},${data?.location?.coordinates?.lng}&hl=en&z=14&output=embed`}
        >
          <a href="https://www.gps.ie/">gps devices</a>
        </iframe>
      </section>

      {data?.faqs?.length > 0 && (
        <div
          className="container mx-auto mt-32 px-4 md:px-8 flex flex-col gap-20"
          ref={scrollToRef}
        >
          <div className="grid lg:grid-cols-1 gap-16">
            <div className="flow-root order-2">
              <h2 className="text-base md:text-2xl lg:mb-2">FAQ'S</h2>
              <div className="divide-y divide-gray-100">
                {data?.faqs?.map((faq, index) => (
                  <details
                    key={index}
                    className="group py-4 lg:py-8 [&_summary::-webkit-details-marker]:hidden"
                  >
                    <summary className="flex cursor-pointer items-center justify-between text-gray-900">
                      <h2 className=" text-sm md:text-base font-bold">
                        {faq.question}
                      </h2>
                      <span className="relative size-5 shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute inset-0 size-5 opacity-100 group-open:opacity-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute inset-0 size-5 opacity-0 group-open:opacity-100"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </summary>
                    <p className="mt-4 leading-relaxed text-gray-70  text-sm md:text-base">
                      {faq.answer}
                    </p>
                  </details>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectDetailsNew;
