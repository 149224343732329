import React from "react";
import { Link } from "react-router-dom";
import GuidesCardShimmer from "./GuidesCardShimmer";

export default function GuidesCardV2({ guide, isLoading = false }) {
  if (isLoading) {
    return <GuidesCardShimmer />;
  }

  return (
    <Link
      reloadDocument
      to={`/guides/${guide?.guide_slug}/`}
      className="relative overflow-hidden rounded-lg w-full h-[300px]"
    >
      {/* Background Image */}
      <img
        src={
          guide?.guide_featured_image?.formats?.medium?.url
            ? guide?.guide_featured_image?.formats?.medium?.url
            : guide?.guide_featured_image?.url
        }
        alt={guide?.guide_name}
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Gradient Overlay */}
      {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-800/60 to-gray-900/90" /> */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="absolute bottom-0 left-0 p-6 text-white">
        <h3 className="text-2xl font-medium mb-2">{guide?.guide_name}</h3>
        <p className="text-sm mb-4">{guide?.hero_heading}</p>
        <div className="inline-flex items-center text-sm font-medium">
          View Guide
          <svg
            className="w-4 h-4 ml-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </div>
      </div>
    </Link>
  );
}
