/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import FormatWidget from "../components/FormatWidget";
import ContactUsForm from "../components/ContactUsForm";
import { Helmet } from "react-helmet";
import { WEB_URL } from "../api/constants";
import { useLocation } from "react-router-dom";

const contactDetails = [
  {
    label: "Phone",
    value: "+971564080888",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532357/xr_media/tqfxtjyxfy8ivwo4dvou.svg",
    action: "tel:+971564080888",
  },
  {
    label: "Landline",
    value: "+97147049400",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532378/xr_media/uw9yqvpqvpd0bdqxmzuk.svg",
    action: "tel:$+97147049400",
  },
  {
    label: "Email",
    value: "info@xrealty.ae",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532391/xr_media/xp04jixsvn7m6n1zsab7.svg",
    action: "mailto:info@xrealty.ae",
  },
  {
    label: "Office Address",
    value:
      "Office no, 301 & 302, Level 3, Building 1, Park Heights Square 1, Dubai Hills Estate, Dubai, UAE PO Box: 65777",
    icon: "https://res.cloudinary.com/dkhns25jh/image/upload/v1739532407/xr_media/otrx6yvaut5x6luxygtw.svg",
    action:
      "https://www.google.com/maps/place/Xperience%E2%80%8B+Realty/@25.1143597,55.2437603,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f69742ae364c3:0xf5f30b07708195fd!8m2!3d25.1143549!4d55.2463352!16s%2Fg%2F11ty7cwknc?entry=tts&shorturl=1",
  },
];

const formFields = [
  { placeholder: "First Name", type: "text", id: "firstname" },
  { placeholder: "Last Name", type: "text", id: "lastname" },
  { placeholder: "Email address", type: "email", id: "email" },
  { placeholder: "Phone Number", type: "tel", id: "phone" },
];

function ContactUs() {
  const { pathname } = useLocation();

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        {/* <title>Contact Us | Dubai Real Estate Agency</title> */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            name: "Contact Us | Xperience Realty | Real Estate Dubai",
            description:
              "Contact our dubai real estate agency for expert guidance and comprehensive services in the dynamic property market.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",

            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>

      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Form Section */}
          <div className="bg-white rounded-xl shadow-sm p-8">
            <FormatWidget
              title={
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                  Get in Touch
                </h1>
              }
              subtitle={
                <p className="text-gray-600 leading-relaxed">
                  Our dedicated team of professionals is available 24/7. Reach
                  out to us through any channel that suits you best.
                </p>
              }
            >
              <ContactUsForm
                formFields={formFields}
                contactDetails={contactDetails}
              />
            </FormatWidget>
          </div>

          {/* Map Section */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <iframe
              width="100%"
              height="100%"
              className="min-h-[400px]"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=xperiance%20realty+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps devices</a>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
