import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { FETCH_BLOGS, NEWS_AND_INSIGHTS, WEB_URL } from "../api/constants";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { WebsiteContext } from "../context/websiteContext";
import { useContext } from "react";
import NewsAndInsightsCardV3 from "../components/NewsAndInsightsCard/NewsAndInsightsCardV3";

function AllNewsInsights() {
  const { setShowAreas } = useContext(WebsiteContext);
  const [newsAndBlogs, setNewsAndBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visibleItems, setVisibleItems] = useState(6);
  const itemsPerLoad = 20;
  const propertyRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    setShowAreas(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${pathname === "/blogs/" ? FETCH_BLOGS : NEWS_AND_INSIGHTS}`
      );
      setNewsAndBlogs(
        pathname === "/blogs/"
          ? response.data.blogs
          : response.data.newsAndBlogs
      );
      setCategories(response.data.categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewMore = () => {
    setVisibleItems((prev) =>
      Math.min(prev + itemsPerLoad, newsAndBlogs.length)
    );
  };

  const handleViewLess = () => {
    setVisibleItems(itemsPerLoad);
  };

  return (
    <div
      className="mt-10 md:mt-16 lg:mt-10 container mx-auto"
      ref={propertyRef}
    >
      <Helmet>
        <link
          rel="alternate"
          hreflang="en-ae"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-gb"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-sg"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en-in"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.xrealty.ae/${pathname.replace("/", "")}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "Xperience Realty | Real Estate Dubai",
            description:
              "Stay informed with the latest Dubai real estate news on our page. Get updated with new trends by subscribing to our updates.",
            image:
              "https://res.cloudinary.com/dkhns25jh/image/upload/w_1280,f_auto,q_auto/v1722498994/xr_media/o08kmuwgagb0jc1gpe4l.png",
            url: WEB_URL + pathname.slice(1),
          })}
        </script>
      </Helmet>

      <div className="px-4 md:mx-auto xl:w-[1200px] lg:mx-auto md:px-6">
        <h1 className="text-xl font-semibold">
          {pathname === "/blogs/" ? "Blogs" : "Real Estate News and Insights"}
        </h1>
        <div className="text-justify text-sm leading-relaxed">
          {pathname === "/blogs/"
            ? "Explore our blog for the latest industry trends and expert suggestions in real estate."
            : "Stay updated with the latest news and insights in the real estate market."}
        </div>

        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
          {newsAndBlogs.slice(0, visibleItems).map((blog, index) => (
            <NewsAndInsightsCardV3
              key={index}
              blog={blog}
              isLoading={!newsAndBlogs?.length > 0}
            />
          ))}
        </div>

        {newsAndBlogs.length > itemsPerLoad && (
          <div className="flex justify-center mt-8 mb-8">
            {visibleItems < newsAndBlogs.length ? (
              <button
                onClick={handleViewMore}
                className="bg-black text-white px-6 py-2 rounded-md hover:bg-gray-700 transition-colors"
              >
                View More
              </button>
            ) : (
              <button
                onClick={handleViewLess}
                className="bg-black text-white px-6 py-2 rounded-md hover:bg-gray-700 transition-colors"
              >
                View Less
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AllNewsInsights;
